/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Main from 'pages/Main'
import Settings from 'pages/Settings'
import Logs from 'pages/Logs'
import Privacyterms from 'pages/Privacyterms'

function AdminRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path=''
          exact
          element={<Main/>}
        />
        <Route
          path='settings'
          exact
          element={<Settings/>}
        />
        <Route
          path='logs'
          exact
          element={<Logs/>}
        />
        <Route
          path='privacy/terms'
          exact
          element={<Privacyterms/>}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AdminRoutes