/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'
import Menu from './Menu'
import ReturnArrow from "./ReturnArrow"
import * as constants from "constants"

function Header(props) {
  return (
    <AppBar position="static">
      <Toolbar style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
      }}>
        <div style={{gridArea: 1 / 1 / 2 / 2, textAlign: 'left'}}>
          {props.showReturnArrow && <ReturnArrow color="inherit"/>}
        </div>
        <div style={{gridArea: 1 / 2 / 2 / 3, textAlign: 'center'}}>
          <Typography variant="h6">
            {constants.appTitle}
          </Typography>
        </div>
        <div style={{gridArea: 1 / 3 / 2 / 4, textAlign: 'right'}}>
          {props.showMenu &&
            <Menu
              color="inherit"
              variant="text"
              style={{borderRadius: 50, minWidth: 0,}}
            />}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header