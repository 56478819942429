/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { VDSP13 } from 'constants'
import {
  Box,
  Divider,
  Typography,
  Grid,
} from '@mui/material'
import {
  CheckCircle,
  Error,
  Warning,
} from '@mui/icons-material'
import i18n from 'i18n-js'
import Utils from 'common/Utils'
import { muiTheme as theme } from 'theme'

export function RawPage({ vds }) {
  const rawData = Utils.arrayBufferToHex(vds.raw, false, 2)
  const rawHeader = vds.header?.raw
    ? Utils.arrayBufferToHex(vds.header.raw, false, 2)
    : undefined
  const rawMessage = vds.message?.raw
    ? Utils.arrayBufferToHex(vds.message.raw, false, 2)
    : undefined
  const rawSignature = vds.signature?.raw
    ? Utils.arrayBufferToHex(vds.signature.raw, false, 2)
    : undefined

  return (
    <Grid
      container
      spacing={2}
      style={{ ...styles.container, ...styles.longText }}
    >
      <Item label="Raw data" value={rawData} xs={12} />
      {vds.header?.raw && <Item label="Raw header data" value={rawHeader} xs={12} />}
      {vds.message?.raw && <Item label="Raw message data" value={rawMessage} xs={12} />}
      {vds.signature?.raw && <Item label="Raw signature data" value={rawSignature} xs={12} />}
    </Grid>
  )
}

export function PrettyPage({ vds }) {
  const subIndicationTexts = {
    [VDSP13.SUB_INDICATION.UNKNOWN_FEATURE]: i18n.t('VDSp13display.UNKNOWN_FEATURE'),
    [VDSP13.SUB_INDICATION.READ_ERROR]: i18n.t('VDSp13display.READ_ERROR'),
    [VDSP13.SUB_INDICATION.EXPIRED_CERTIFICATE]: i18n.t('VDSp13display.EXPIRED_CERTIFICATE'),
    [VDSP13.SUB_INDICATION.WRONG_FORMAT]: i18n.t('VDSp13display.WRONG_FORMAT'),
    [VDSP13.SUB_INDICATION.UNKNOWN_CERTIFICATE]: i18n.t('VDSp13display.UNKNOWN_CERTIFICATE'),
    [VDSP13.SUB_INDICATION.UNTRUSTED_CERTIFICATE]: i18n.t('VDSp13display.UNTRUSTED_CERTIFICATE'),
    [VDSP13.SUB_INDICATION.INVALID_DOCUMENTTYPE]: i18n.t('VDSp13display.INVALID_DOCUMENTTYPE'),
    [VDSP13.SUB_INDICATION.REVOKED_CERTIFICATE]: i18n.t('VDSp13display.REVOKED_CERTIFICATE'),
    [VDSP13.SUB_INDICATION.INVALID_SIGNATURE]: i18n.t('VDSp13display.INVALID_SIGNATURE'),
  }

  const [openInfo, setOpenInfo] = React.useState(false)

  return (
    <div style={styles.container}>
      {vds.status === VDSP13.STATUS.VALID
        ? <>
            <Grid container style={styles.successBox}>
              <Grid item xs={2}>
                <CheckCircle style={styles.bigIcon} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6">{i18n.t('VDSp13display.AUTHENTIC_TITLE')}</Typography>
                <Typography variant="body1">{i18n.t('VDSp13display.AUTHENTIC_TEXT')}</Typography>
              </Grid>
              <Grid item xs={1} style={styles.centerContent}>
                <IconButton style={styles.smallButton} onClick={() => {setOpenInfo(!openInfo)}}>
                  <InfoOutlined style={styles.smallIcon} />
                </IconButton>
              </Grid>
              <Collapse in={openInfo} timeout="auto" style={styles.collapse}>
                <Divider style={styles.infoDivider} />
                <Grid container spacing={1}>
                  <Grid item style={styles.centerContent} xs={2}>
                    <WorkspacePremium style={styles.mediumIcon} />
                  </Grid>
                  <Item title value={i18n.t('VDSp13.PKI_TEXT')} xs={10} />
                  <Item label={i18n.t('VDSp13.SIGNER_CERTIFICATE_LABEL')} value={vds.signerCertificate.cacheObject.label} xs={6} />
                  <Item label={i18n.t('VDSp13.CA_CERTIFICATE_LABEL')} value={vds.CACertificate.cacheObject.label} xs={6} />
                </Grid>
              </Collapse>
            </Grid>
            {vds.subIndication && subIndicationTexts[vds.subIndication] && 
              <Grid container style={styles.warningBox}>
                <Grid item xs={2}>
                  <Warning style={styles.mediumIcon} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">
                    {subIndicationTexts[vds.subIndication]}
                  </Typography>
                </Grid>
              </Grid>
            }
          </>
        : <Grid container style={styles.errorBox}>
            <Grid item xs={2}>
              <Error style={styles.bigIcon} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6">{i18n.t('VDSp13display.INVALID_TITLE')}</Typography>
              {vds.subIndication && subIndicationTexts[vds.subIndication] && 
                <Typography variant="body1">
                  {subIndicationTexts[vds.subIndication]}
                </Typography>
              }
            </Grid>
          </Grid>
      }
      <Grid container spacing={2} style={styles.longText}>
        {(vds.header?.signerIdentifier
          || vds.header?.certificateReference
          || vds.header?.documentIssueDate
          || vds.header?.signatureCreationDate
          || vds.header?.issuingCountry) && (
            <Item title value={i18n.t('VDSp13display.HEADER')} xs={12} />
          )}
        {vds.header?.signerIdentifier && (
          <Item label={i18n.t('VDSp13display.SIGNER_IDENTIFIER')} value={vds.header.signerIdentifier} xs={6} />
        )}
        {vds.header?.certificateReference && (
          <Item label={i18n.t('VDSp13display.CERTIFICATE_REFERENCE')} value={vds.header.certificateReference} xs={6} />
        )}
        {vds.header?.documentIssueDate && (
          <Item label={i18n.t('VDSp13display.DOCUMENT_ISSUE_DATE')} value={vds.header.documentIssueDate} xs={6} />
        )}
        {vds.header?.signatureCreationDate && (
          <Item label={i18n.t('VDSp13display.SIGNATURE_CREATION_DATE')} value={vds.header.signatureCreationDate} xs={6} />
        )}
        {vds.header?.issuingCountry && (
          <Item label={i18n.t('VDSp13display.ISSUING_COUNTRY')} value={vds.header.issuingCountry} xs={12} />
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {vds.message?.documentFeatures &&
          <>
            <Item title value={i18n.t('VDSp13display.MESSAGE')} xs={12} />
            {Object.entries(vds.message.documentFeatures).map(([key, value], index) => (
              <Item key={`item-${index}`} label={key} value={value} xs={12}/>
            ))}
          </>
        }
      </Grid>
    </div>
  )
}

function Item(props) {
  function Content(props) {
    if (props.name) {
      return <Typography variant="h5">{props.value.toUpperCase()}</Typography>
    }

    if (props.title) {
      return <Typography variant="h6">{props.value}</Typography>
    }
    
    return (
      <>
        <Typography
          variant={props.inline ? 'body1' : 'body2'}
          display={props.inline ? 'inline' : 'block'}
        >
          {`${props.label}: `}
        </Typography>
        {props.value !== undefined &&
          <Typography
            variant="body1"
            display={props.inline ? 'inline' : 'block'}
          >
            <Box fontWeight='bold' component="span">{props.value}</Box>
          </Typography>
        }
        {props.children &&
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: 5 }}
          >
            <Divider orientation="vertical" flexItem style={{ margin: '0 10px' }} />
            <Grid container spacing={1}>
              {props.children}
            </Grid>
          </Box>
        }
      </>
    )
  }

  return (
    <Grid item xs={props.xs}>
      <Content {...props} />
    </Grid>
  )
}

const styles = {
  container: {
    padding: 20,
  },
  longText:{
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  successBox: {
    borderRadius: 15,
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    alignItems: 'center',
    padding: 15,
    marginBottom: 20
  },
  errorBox: {
    borderRadius: 15,
    backgroundColor: theme.palette.red.main,
    color: theme.palette.white.main,
    alignItems: 'center',
    padding: 15,
    marginBottom: 20
  },
  warningBox: {
    borderRadius: 15,
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.white.main,
    alignItems: 'center',
    padding: 15,
    marginBottom: 20
  },
  bigIcon: {
    fontSize: 50
  },
  mediumIcon: {
    fontSize: 30
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  smallIcon: {
    color: theme.palette.white.main,
    fontSize: 20
  },
  smallButton: {
    minWidth: 0,
    borderRadius: 50,
  },
  collapse: {
    width: '100%'
  },
  infoDivider: {
    backgroundColor: theme.palette.white.main,
    marginTop: 15,
    marginBottom: 15
  },
}