/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import moment from 'moment'
import * as idb from 'idb'
import { CACHE_VALUES } from 'constants'
import { dateTimeMsFormat } from 'theme'

class Cache {
  static dbVersion = 1

  static openStorage = async (tableName, options) => {
    if (!window.indexedDB) {
      return
    }

    return idb.openDB(CACHE_VALUES.dbName, Cache.dbVersion, {
      upgrade(db, oldVersion, newVersion, transaction) {
        db.createObjectStore(tableName, options)
      }
    })
  }

  static getTransaction = (db, tableName) => {
    return db.transaction([tableName], "readwrite").objectStore(tableName)
  }

  static get = async (tableName, key) => {
    const db = await Cache.openStorage(tableName)
    return Cache.getTransaction(db, tableName).get(key)
  }

  static getAll = async (tableName) => {
    const db = await Cache.openStorage(tableName)
    return Cache.getTransaction(db, tableName).getAll()
  }

  static put = async (tableName, value, key) => {
    // accepts value and key or just value for in-line keys
    const db = await Cache.openStorage(tableName)
    const transaction = Cache.getTransaction(db, tableName)
    key ? transaction.put(value, key) : transaction.put(value)
    return { key: key, value: value }
  }

  static remove = async (tableName, key) => {
    const db = await Cache.openStorage(tableName)
    return Cache.getTransaction(db, tableName).delete(key)
  }

  static log = (level, text, data) => {
    const timestamp = moment().format(dateTimeMsFormat)
    const expirationDate = moment()
      .add(CACHE_VALUES.defaultExpirationDuration, 'ms')
      .format(dateTimeMsFormat)

    const log = {
      timestamp: timestamp,
      expirationDate: expirationDate,
      level: level,
      text: text,
      data: data,
    }

    Cache.put(CACHE_VALUES.tables.logs.name, log)
  }

  static clearTable = async (table) => {
    const db = await Cache.openStorage(table.name)
    const transaction = db.transaction(table.name)

    let count = await transaction.store.count()

    // Filter by expiration date
    if (table.expirationField) {
      const currentDateTime = new moment()
      let cursor = await transaction.store.openCursor()
      while (cursor) {
        const record = cursor.value
        const expirationDate = record[table.expirationField]
          ? moment(record[table.expirationField], dateTimeMsFormat)
          : null

        if (expirationDate && expirationDate.isBefore(currentDateTime)) {
          count--
          remove(table.name, record[table.key])
        }

        cursor = await cursor.continue()
      }
    }

    // Filter by count (start removing from the end of table)
    if (table.maxCount && count >= table.maxCount) {
      let cursor = await transaction.store.openCursor(null, 'prev')
      const toRemove = count - table.maxCount

      for (let i = 0; i < toRemove; i++) {
        const record = cursor.value
        remove(table.name, record[table.key])
        cursor = await cursor.continue()
      }
    }
  }

  static init = async () => {
    if (!window.indexedDB) {
      return
    }

    // open DB to get current version and check if tables exist
    let db = await idb.openDB(CACHE_VALUES.dbName)
    let needsUpgrade = false

    // Check if all tables exist
    for (const table of Object.values(CACHE_VALUES.tables)) {
      if (!db.objectStoreNames.contains(table.name)) {
        needsUpgrade = true
        break
      }
    }

    Cache.dbVersion = db.version

    db.close()

    if (needsUpgrade) {
      const newVersion = db.version + 1
      Cache.dbVersion = newVersion

      // add missing tables to new version
      db = await idb.openDB(CACHE_VALUES.dbName, newVersion, {
        upgrade(_db) {
          for (const table of Object.values(CACHE_VALUES.tables)) {
            if (!_db.objectStoreNames.contains(table.name)) {
              const options = table.key ? { keyPath: table.key } : undefined
              _db.createObjectStore(table.name, options)
            }
          }
        }
      })
    }

    // clear tables
    for (const table of Object.values(CACHE_VALUES.tables)) {
      Cache.clearTable(table)
    }

    db.close()
  }
}

export default Cache