/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'

import { Dialog } from 'tegona-react'
import Header from './Header'
import Menu from './Menu'
import { useLocation } from 'react-router-dom'
import { closeSnackbar } from 'notistack'

function Base(props) {
  const location = useLocation()

  React.useEffect(() => {
    closeSnackbar() // close all open snackbars on page change
  }, [location])

  return (
    <div style={styles.container}>
      <div style={{zIndex: 10}}>
        {props.showDialog &&
          <Dialog
            open={props.showDialog}
            title={props.dialogTitle}
            {
              ...props.dialogProps
            }
          >
            {props.dialogContent}
          </Dialog>}
        {props.showHeader &&
          <Header
            showReturnArrow={props.showReturnArrow !== false}
            showMenu={props.showHeaderMenu !== false}
          />}
        {props.showMenu &&
          <Menu
            style={{...styles.menuButton, ...props.menuButtonStyle}}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
          />}
      </div>

      <div style={{zIndex: 0, height: 'calc(100% - 64px)'}}>
        {props.children}
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  menuButton: {
    position: 'absolute',
    right: '5vw',
    bottom: '5vw',
    width: 60,
    height: 60,
    borderRadius: 50,
    minWidth: 0,
    zIndex: 10,
  }
}

export default Base