/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

export const frTranslation = {
  Menu: {
    SETTINGS: "Réglages",
    LOGS: "Affichage des logs",
    PRIVACYTERMS: "Privacy & Terms"
  },
  Settings: {
    EMPTY_LABEL: "Le label ne peut pas être vide",
    DIALOG_TITLE: "Ajoutez un certificat",
    DIALOG_DESCRIPTION: "Importez un certificat depuis votre appareil",
    DIALOG_SUBMIT: "Ajouter",
    DIALOG_CANCEL: "Annuler",
    NO_FILE_SELECTED: "Aucun fichier sélectionné",
    SELECT_FILE: "Sélectionner un fichier",
    CERTIFICATE_FETCHING_ERROR: "Erreur lors de la récupération des certificats",
    CERTIFICATE_ALREADY_EXISTS: "Le certificat est déjà enregistré",
    CERTIFICATE_CACHING_ERROR: "Erreur lors de la sauvegarde du certificat",
    CERTIFICATE_PARSING_ERROR: "Erreur lors de l'analyse du certificat",
    CERTIFICATE_REMOVING_ERROR: "Erreur lors de la suppression du certificat",
  },
  Logs : {
    SHARE_TITLE: "VdsChecker",
    SHARE_TEXT: "Logs VdsChecker",
    SHARE_ERROR: "Erreur lors du partage des logs",
    CACHE_ERROR: "Erreur lors de la récupération des logs",
  },
  BarCodeScanner: {
    STREAM_ERROR: "Unknown stream error",
    CAMERA_ERROR: "Erreur lors du chargement de la caméra",
    NO_PREFERRED_CAMERA: "Aucune caméra préférée trouvée",
  },
  DataDrawer: {
    EDGE_TEXT: "Faites glisser pour voir les détails",
  },
  Crypto: {
    INVALID_EC_PUBLIC_KEY: "Clé publique EC invalide",
    UNKNOWN_HASH_FUNCTION: "Fonction de hachage inconnue",
    UNKNOWN_CERTIFICATE_FORMAT: "Format de certificat inconnu",
    ASN1_PARSING_ERROR: "Erreur lors de l'analyse ASN.1",
    UNKNOWN_ECDSA_CURVE: "Courbe ECDSA inconnue",
    COMPRESSED_POINT_NOT_SUPPORTED: "Point de base de courbe compressé non supporté",
    UNKNOWN_EC_PARAMETERS_FORMAT: "Format de paramètres de courbe elliptique inconnu",
    CERTIFICATE_PARSE_ERROR: "Erreur lors de l'analyse du certificat",
    UNKNOWN_SIGNATURE_ALGORITHM: "Algorithme de signature inconnu",
    INVALID_SIGNATURE_ALGORITHM: "Algorithme de signature invalide",
    INVALID_ISSUER_CERTIFICATE: "Certificat émetteur invalide",
    CERTIFICATE_SIZE_LIMIT: "La taille du certificat est trop grande",
    INVALID_PUBLIC_KEY_ALGORITHM: "Algorithme de clé publique invalide",
    INVALID_EC_PARAMETERS_FORMAT: "La courbe elliptique devrait être nommée",
    INVALID_EXTENDED_KEY_USAGE: "Extension 'ExtendedKeyUsage' invalide",
    INVALID_DOCUMENT_TYPE_EXTENSION: "Extension 'DocumentType' invalide",
    INVALID_DOCUMENT_TYPE_VERSION: "Extension 'DocumentType': version invalide, devrait être 0",
    MISSING_DOCUMENT_TYPE: "Extension 'DocumentType': type de document manquant: devrait contenir '%s'",
    INVALID_AUTHORITY_KEY_IDENTIFIER: "Extension 'AuthorityKeyIdentifier' invalide",
  },
  VDSDataPage: {
    NOT_A_VDS: "Le document scanné n'est pas un VDS",
    ERROR_PAGE_TEXT: "Le document scanné ne contient pas de VDS valide.",
    TAB_1: "VDS",
    TAB_2: "Données brutes",
  },
  VDSp13: {
    NOT_APPLICABLE: "N/A",
    INVALID_READ: "Lecture invalide",
    INVALID_MAGIC_CONSTANT: "Constante magique invalide",
    INVALID_DOCUMENT_FEATURE_DEFINITION_REFERENCE: "Référence de la définition des caractéristiques du document invalide",
    INVALID_DOCUMENT_TYPE_CATEGORY: "Catégorie de type de document invalide",
    INVALID_SIGNATURE_MARKER: "Marqueur de signature invalide",
    INVALID_TLV_LENGTH: "Longueur TLV invalide",
    SIGNER_CERTIFICATE_NOT_FOUND: "Certificat du signataire non trouvé",
    SIGNER_CERTIFICATE_NOT_YET_VALID: "Certificat du signataire pas encore valide",
    SIGNER_CERTIFICATE_EXPIRED: "Certificat du signataire expiré",
    INVALID_SIGNATURE: "Signature invalide",
  },
  VDSp13display: {
    AUTHENTIC_TITLE: "Le VDS est authentique",
    INVALID_TITLE: "Le VDS est invalide",
    AUTHENTIC_TEXT: "Le document a été vérifié avec succès",
    UNKNOWN_FEATURE: "Le VDS contient un champ inconnu",
    READ_ERROR: "Une erreur de lecture du VDS s'est produite",
    WRONG_FORMAT: "Le format du document est invalide",
    EXPIRED_CERTIFICATE: "Le certificat qui a signé le VDS est expiré",
    UNKNOWN_CERTIFICATE: "Le certificat qui a signé le VDS est inconnu",
    UNTRUSTED_CERTIFICATE: "Le certificat qui a signé le VDS n'est pas fiable",
    INVALID_DOCUMENTTYPE: "Le type de document est invalide",
    REVOKED_CERTIFICATE: "Le certificat qui a signé le VDS a été révoqué",
    INVALID_SIGNATURE: "La signature du VDS est invalide",
    HEADER: "En-tête",
    ISSUING_COUNTRY: "Pays de délivrance",
    SIGNER_IDENTIFIER: "Identifiant du signataire",
    CERTIFICATE_REFERENCE: "Référence du certificat",
    DOCUMENT_ISSUE_DATE: "Date de délivrance du document",
    SIGNATURE_CREATION_DATE: "Date de création de la signature",
    MESSAGE: "Contenu",
  },
  VDSp13decode: {
    DURATION_OF_STAY_INVALID_LENGTH: "Longueur de la durée de séjour invalide",
    INTEGER_INVALID_LENGTH: "Longueur de l'entier invalide",
    DATE_INVALID: "Date invalide",
    DATE_INVALID_LENGTH: "Longueur de la date invalide",
    C40_INVALID_ASCII: "Caractère C40 invalide",
    C40_ODD_LENGTH: "Longueur C40 impaire",
    C40_INVALID_VALUE: "Valeur C40 invalide",
    UNKNOWN_DOCUMENT_TYPE_CATETORY: "Catégorie de type de document inconnue",
    UNKNOWN_DOCUMENT_FEATURE: "Caractéristique du document inconnue",
    UNKNOWN_DOCUMENT_FEATURE_DEFINITION_REFERENCE: "Référence de la définition des caractéristiques du document inconnue",
    INVALID_FORMAT: "Format invalide",
    DOCUMENT_FEATURE_INVALID_LENGTH: "Longueur de la caractéristique du document invalide",
    DOCUMENT_FEATURE_MISSING: "Caractéristique du document manquante",
    INVALID_VERSION: "Version invalide",
    MRZ_MRV_A: "ZLA de VLM-A",
    MRZ_MRV_B: "ZLA de VLM-B",
    NUMBER_OF_ENTRIES: "Nombre d'entrées",
    DURATION_OF_STAY: "Durée de séjour",
    PASSPORT_NUMBER: "Numéro de passeport",
    VISA_TYPE: "Type de visa",
    ADDITIONAL_FEATURES: "Caractéristiques supplémentaires",
    MRZ: "ZLA",
    AZR_NUMBER: "Numéro AZR",
    SOCIAL_INSURANCE_NUMBER: "Numéro d'assurance sociale",
    SURNAME: "Nom",
    FIRST_NAME: "Prénom",
    NAME_AT_BIRTH: "Nom de naissance",
    MRZ_OF_RESIDENCE_PERMIT_DOCUMENT: "ZLA du document de permis de séjour",
    SUPPLEMENTARY_SHEETS_NUMBER: "Nombre de feuilles supplémentaires",
    DOCUMENT_NUMBER: "Numéro de document",
    OFFICIAL_MUNICIPALITY_CODE_NUMBER: "Numéro de code municipal officiel",
    RESIDENTIAL_ADDRESS: "Adresse de résidence",
    OFFICIAL_MUNICIPAL_CODE_NUMBER: "Numéro de code municipal officiel",
    POSTAL_CODE: "Code postal",
  },
  VDSNC: {
    SIGNER_CERTIFICATE: "Certificat signataire",
    NOT_APPLICABLE: "N/A",
    UNKNOWN_TYPE: "Type de VDS-NC inconnu",
    NOT_JSON: "Le code-barres ne contient pas de données JSON",
    NOT_VDSNC: "Le code-barres ne contient pas de VDS-NC valide",
    INVALID_SIGNATURE_ALGORITHM: "Algorithme de signature inconnu",
    POT_INVALID_FORMAT: "PoT ICAO invalide: format invalide",
    POT_NOT_SIGNED: "PoT ICAO invalide: non signé",
    POT_INVALID_DOCUMENT_TYPE: "PoT ICAO invalide: type de document d'identité invalide",
    POT_MISSING_UTCI: "PoT ICAO invalide: identifiant unique de certificat de test manquant",
    POR_INVALID_FORMAT: "PoR ICAO invalide: format invalide",
    POR_NOT_SIGNED: "PoR ICAO invalide: non signé",
    POR_INVALID_DOCUMENT_TYPE: "PoR ICAO invalide: type de document d'identité invalide",
    POV_INVALID_FORMAT: "PoV ICAO invalide: format invalide",
    POV_NOT_SIGNED: "PoV ICAO invalide: non signé",
    POV_MISSING_DOB_UID: "PoV ICAO invalide: date de naissance ou identifiant unique manquant",
    POV_MISSING_MFG_MAH: "PoV ICAO invalide: fabricant du vaccin ou autorité de délivrance manquant",
    POV_INVALID_VERSION: "PoV ICAO invalide: version invalide",
    DTA_INVALID_FORMAT: "DTA ICAO invalide: format invalide",
    DTA_NOT_SIGNED: "DTA ICAO invalide: non signé",
    CERTIFICATE_NOT_FOUND: "Certificat non trouvé",
    INVALID_CERTIFICATE: "Certificat invalide",
  },
  VDSNCdisplay: {
    SIGNATURE_NOT_VERIFIED: "Signature non vérifiée",
    SIGNATURE_VERIFIED: "Signature vérifiée",
    AUTHENTIC_TITLE: "Le VDS-NC est authentique",
    INVALID_TITLE: "Le VDS-NC est invalide",
    AUTHENTIC_TEXT: "Le document a été vérifié avec succès",
    PKI_TEXT: "Signature vérifiée",
    ISSUING_COUNTRY: "Pays émetteur",
    SIGNER_CERTIFICATE_LABEL: "Certificat signataire",
    CA_CERTIFICATE_LABEL: "Certificat d'autorité",
    CERTIICATE_REFERENCE: "Référence du certificat signataire",
    INVALID_TEXT: "Le document n'a pas pu être vérifié",
    ERROR_TEXT: "Les erreurs suivantes ont été trouvées:",
    DATE_OF_BIRTH: "Date de naissance",
    NATIONALITY: "Nationalité",
    PASSPORT_NUMBER: "Numéro de passeport",
    SEX: "Sexe",
    DTA_TITLE: "Autorisation de voyage",
    DTA_NUMBER: "Numéro DTA",
    PLACE_OF_ISSUE: "Lieu de délivrance",
    VALID_FROM: "Valide à partir du",
    VALID_UNTIL: "Valide jusqu'à",
    DURATION_OF_STAY: "Durée de séjour",
    NUMBER_OF_ENTRIES: "Nombre d'entrées",
    VISA_TYPE: "Type de visa",
    ADDITIONAL_INFO: "Informations supplémentaires",
    DOCUMENT_TYPE: "Type de document",
    DOCUMENT_NUMBER: "Numéro de document",
    POT_TITLE: "Certificat de test",
    SERVICE_PROVIDER: "Fournisseur de service",
    CONTACT_DETAILS: "Coordonnées",
    DATE_SPECIMEN_COLLECTION: "Date de prélèvement",
    DATE_REPORT_ISSUANCE: "Date de délivrance du rapport",
    TEST_RESULT: "Résultat du test",
    TEST_TYPE: "Type de test",
    SAMPLING_METHOD: "Méthode d'échantillonnage",
    RESULT: "Résultat",
    OPTIONAL_DATA: "Données optionnelles",
    POR_TITLE: "Certificat de rétablissement",
    COUNTRY_OF_ISSUE: "Pays de délivrance",
    DATE_OF_FIRST_POSITIVE: "Date du premier test NAAT positif",
    URCI: "URCI",
    UTCI: "UTCI",
    UVCI: "UVCI",
    VACCINATION_EVENTS: "Événements de vaccination",
    ADDITIONAL_IDENTIFIER: "Identifiant supplémentaire",
    TRAVEL_DOCUMENT_NUMBER: "Numéro de document de voyage",
    VACCINE_PROPHYLAXIS: "Vaccin/prophylaxie",
    VACCINE_BRAND: "Marque du vaccin",
    VACCINE_MANUFACTURER: "Fabricant du vaccin",
    VACCINE_MAH: "Autorité de délivrance du vaccin",
    DISEASE_AGENT: "Agent pathogène",
    VACCINATION_DETAILS: "Détails",
    DATE_OF_VACCINATION: "Date de vaccination",
    DOSE_NUMBER: "Numéro de dose",
    TOTAL_DOSES: "Nombre total de doses",
    COUNTRY_OF_VACCINATION: "Pays de vaccination",
    ADMINISTERING_CENTRE: "Centre d'administration",
    VACCINE_BATCH_NUMBER: "Numéro de lot du vaccin",
    DUE_DATE_NEXT_DOSE: "Date de la prochaine dose",
    DURATION_OF_STAY_YEARS: 'ans',
    DURATION_OF_STAY_MONTHS: 'mois',
    DURATION_OF_STAY_DAYS: 'jours',
    NUMBER_OF_ENTRIES_MULTIPLE: 'Multiple'
  }
}