/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import Cache from 'common/Cache'
import * as constants from 'constants'
import i18n from 'i18n-js'
import { muiTheme as theme } from 'theme'
import { CircularProgress } from 'tegona-react'
import { Check, PhotoCamera } from "@mui/icons-material"
import {
  Button,
  MenuItem,
  Menu,
  ListItemText,
  ListItemIcon,
  MenuList,
} from '@mui/material'
import Snackbar from 'common/Snackbar'
import {
  readBarcodesFromImageData,
  setZXingModuleOverrides
} from "zxing-wasm/reader"

// Override the default locateFile function to load the wasm file from
// publix/zxing/ instead of using a CDN
setZXingModuleOverrides({
  locateFile: (path, prefix) => {
    if (path.endsWith(".wasm")) {
      return `/zxing/${path}`
    }
    return prefix + path
  },
})

class BarCodeScanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      cameras: [],
      selectedCamera: null,
      menu: null,
    }
    this.videoRef = React.createRef()
    this.streams = []
    this.isScanning = false

    this.handleCameraSelect = this.handleCameraSelect.bind(this)
  }

  componentDidMount() {
    this.requestPermissions()
  }

  componentWillUnmount() {
    this.handleCloseScanner()
  }

  static streams = []
  static capturePerSecond = 30
  static streamConstraints = {
    audio: false,
    video: {
      facingMode: 'environment',
      width: { ideal: 1920 },
      height: { ideal: 1080 },
      exposureMode: 'continuous',
      whiteBalanceMode: 'continuous',
      focusMode: 'continuous',
    },
  }

  /**
   * Reader options described here:
   * https://github.com/Sec-ant/zxing-wasm/blob/2b4d35ac555777dfe546dfd7b37b2101dc6c7bf5/src/bindings/readerOptions.ts
   */
  static readerOptions = {
    formats: ["Matrix-Codes"], // 2D ISO barcodes
    binarizer: "LocalAverage", // default
    characterSet: "Unknown", // auto-detect
    maxNumberOfSymbols: 1, // One barcode per image
    minLineCount: 2, // default
    eanAddOnSymbol: "Ignore", // ignore EAN barcode
    textMode: "Plain", // default
    tryHarder: false, // improves accuracy but is slower
    tryRotate: true, // default
    tryInvert: true, // default
    isPure: false, // default
    returnErrors: false, // default
    tryDownscale: true, // default
    downscaleThreshold: 500, // default
    downscaleFactor: 3, // default
    tryCode39ExtendedMode: false, // default
    validateCode39CheckSum: false, // default
    validateITFCheckSum: false, // default
    returnCodabarStartEnd: false, // default
  }

  releaseAllStreams() {
    this.streams.forEach((stream) => {
      try {
        stream.getTracks().forEach((track) => track.stop())
      } catch (error) {
        // Unknown stream, ignore
        Cache.log(constants.DEBUG, i18n.t('BarCodeScanner.STREAM_ERROR'), error.toString())
      }
    })
    this.streams = []
  }

  requestPermissions = async () => {
    try {
      let mediaStream = await navigator.mediaDevices.getUserMedia(
        { audio: false, video: true }
      )

      this.streams.push(mediaStream)
      
      // list video devices after getting permissions
      this.getAvailableCameras()

      // Release video stream after listing cameras, this function is just
      // making the camera permission request necessary to list camera devices
      this.releaseAllStreams()
    } catch (error) {
      Cache.log(constants.ERROR, i18n.t('BarCodeScanner.CAMERA_ERROR'), error.toString())
      Snackbar.enqueue({
        message: i18n.t('BarCodeScanner.CAMERA_ERROR'),
        variant: 'error',
      })
    }
  }

  getAvailableCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter(device => device.kind === 'videoinput')
      
      // Seek last used camera, or default to the first camera found
      const preferredCamera = await this.getPreferredCamera(videoDevices)

      this.setState({
        cameras: videoDevices,
        selectedCamera: preferredCamera,
      },this.handleOpenScanner)
    } catch (error) {
      Cache.log(constants.DEBUG, i18n.t('BarCodeScanner.CAMERA_ERROR'), error.toString())
      Snackbar.enqueue({
        message: i18n.t('BarCodeScanner.CAMERA_ERROR'),
        variant: 'error',
      })
    }
  }

  getPreferredCamera = async (cameras) => {
    const defaultCamera = cameras?.length ? cameras[0] : null

    let preferredCamera
    try {
      preferredCamera = await Cache.get(constants.CACHE_VALUES.tables.preferences.name, 'preferredCamera')

      return preferredCamera
        ? cameras.find(camera => camera.label === preferredCamera.label) || defaultCamera
        : defaultCamera
    }
    catch (error) {
      Cache.log(constants.DEBUG, i18n.t('BarCodeScanner.NO_PREFERRED_CAMERA'), error.toString())
      return defaultCamera
    }
  }

  start() {
    this.isScanning = true
    this.decodeContinuously()
  }

  stop() {
    this.isScanning = false
  }

  handleOpenScanner = async () => {
    const video = this.videoRef.current
    video.setAttribute('playsinline', true)
    video.setAttribute('muted', true)
    video.setAttribute('autoplay', true)

    let streamConstraints = BarCodeScanner.streamConstraints
    if (this.state.selectedCamera) {
      streamConstraints.video.deviceId = {
        exact: this.state.selectedCamera.deviceId
      }
    }

    const stream = await navigator.mediaDevices
      .getUserMedia(streamConstraints)
      .catch((error) => {
        Cache.log(constants.DEBUG, i18n.t('BarCodeScanner.CAMERA_ERROR'), error)
        Snackbar.enqueue({
          message: i18n.t('BarCodeScanner.CAMERA_ERROR'),
          variant: 'error',
        })
      })

    this.streams.push(stream)
    video.srcObject = stream
    video.play().catch((error) => { }) // Ignore

    this.start() // start scanning
  }

  handleCloseScanner = () => {
    this.stop()
    this.releaseAllStreams()
  }

  decodeContinuously = async () => {
    const loop = async () => {
      if (!this.isScanning) {
        return
      }

      try {
        const video = this.videoRef.current

        // Create a canvas element to capture the video frame
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
  
        // Draw the video frame to the canvas
        const context = canvas.getContext('2d', { willReadFrequently: true })
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Read barcode(s) from the canvas
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
        const results = await readBarcodesFromImageData(
          imageData,
          BarCodeScanner.readerOptions
        )

        if (results.length > 0) {
          this.props.onScanSuccess && this.props.onScanSuccess(results[0])
          this.stop()
          return // state change is not immediate
        }
      } catch (error) {
        // Ignore
      }
      setTimeout(loop, 1000 / BarCodeScanner.capturePerSecond)
    }

    loop()
  }

  handleCameraSelect(camera) {
    if (camera === this.state.selectedCamera) return

    this.setState({ loading: true })
    Cache.put(
      constants.CACHE_VALUES.tables.preferences.name,
      { label: camera.label, deviceId: camera.deviceId },
      'preferredCamera',
    )
    this.setState({
      selectedCamera: camera,
      menu: null,
    }, () => {
      this.handleCloseScanner()
      this.handleOpenScanner()
    })
  }

  render() {
    return (
      <>
        {this.state.loading &&
          <CircularProgress
            style={styles.circularProgress}
            size={100}
          />
        }
        
        <Button
          variant="contained"
          style={{...styles.cameraButton, opacity: this.state.loading ? 0 : 1}}
          onClick={(event) => this.setState({ menu: event.currentTarget })}
        >
          <PhotoCamera sx={{ fontSize: 28 }} />
        </Button>

        <Menu
          anchorEl={this.state.menu}
          open={Boolean(this.state.menu)}
          onClose={() => this.setState({ menu: null })}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          MenuListProps={{ sx: { py: 0 } }}
        >
          <MenuList>
            {this.state.cameras.map((camera, index) => {
              const isSelected = camera.label === this.state.selectedCamera?.label
              return (
                <MenuItem
                  selected={isSelected}
                  key={`camera-${index}`}
                  onClick={!isSelected && (() => this.handleCameraSelect(camera))}
                >
                  <ListItemIcon>
                    {isSelected ? <Check /> : <PhotoCamera />}
                  </ListItemIcon>
                  <ListItemText
                    primary={camera.label || `Camera ${index + 1}`}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>

        <video
          ref={this.videoRef}
          onLoadedData={() => this.setState({ loading: false })}
          style={{...styles.video, opacity: this.state.loading ? 0 : 1}}
        />
      </>
    )
  }
}

const styles = {
  video: {
    pointerEvents: 'none',
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0,
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cameraButton: {
    backgroundColor: theme.lightGrey,
    color: theme.black,
    position: 'absolute',
    right: '5vw',
    top: '5vw',
    width: 60,
    height: 60,
    borderRadius: 50,
    minWidth: 0,
    zIndex: 10,
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0,
  }
}

export default React.forwardRef((props, ref) => {
  const scannerRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    start: () => scannerRef.current.start(),
    stop: () => scannerRef.current.stop(),
  }))

  return <BarCodeScanner ref={scannerRef} {...props} />
})
