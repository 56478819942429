/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { Button, MenuItem, Menu as MenuComponent } from "@mui/material"
import { Menu as MenuIcon } from "@mui/icons-material"
import i18n from 'i18n-js'
import { useNavigate } from 'react-router-dom'

function Menu(props) {
  const [menu, setMenu] = React.useState()

  const navigate = useNavigate()

  const handleClick = (event) => {
    setMenu(event.currentTarget)
  }

  const handleClose = () => {
    setMenu(null)
  }

  return (
    <React.Fragment>
      <Button
        color={props.color || "primary"}
        variant={props.variant || "contained"}
        style={props.style}
        onClick={handleClick}
      >
        <MenuIcon sx={{...props.sx, fontSize: 28}}/>
      </Button>
      <MenuComponent
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {navigate("/settings/")}}>{i18n.t("Menu.SETTINGS")}</MenuItem>
        <MenuItem onClick={() => {navigate("/logs/")}}>{i18n.t("Menu.LOGS")}</MenuItem>
        <MenuItem onClick={() => {navigate("/privacy/terms/")}}>{i18n.t("Menu.PRIVACYTERMS")}</MenuItem>
      </MenuComponent>
    </React.Fragment>
  )
}

export default Menu