/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import Base from 'common/Base'
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Skeleton,
  Pagination,
  Modal,
  Box,
  Typography,
  Fade,
} from '@mui/material'
import {
  IosShare,
  InfoOutlined,
  BugReport,
  ErrorOutline,
  QuestionMark
} from '@mui/icons-material'
import Cache from 'common/Cache'
import * as constants from 'constants'
import { CACHE_VALUES } from 'constants'
import i18n from 'i18n-js'
import Snackbar from 'common/Snackbar'

function Logs() {
  const [pageLogs, setPageLogs] = React.useState([])
  const [allLogs, setAllLogs] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(false)
  const [selectedLog, setSelectedLog] = React.useState(null)
  const listRef = React.useRef(null)

  React.useEffect(() => {
    async function getAllLogs() {
      const logs = await Cache.getAll(CACHE_VALUES.tables.logs.name)
        .catch((error) => {
          Cache.log(constants.ERROR, i18n.t('Logs.CACHE_ERROR'), error.toString())
          Snackbar.enqueue({
            message: i18n.t('Logs.CACHE_ERROR'),
            variant: 'error',
          })
          return []
        })

      setAllLogs(logs.reverse()) // most recent logs first
      setPageLogs(logs.slice(0, constants.LOGS_PER_PAGE))
      setLoading(false)
    }

    getAllLogs()
  }, [])

  function getNthPage(n) {
    // scroll list back to top
    if (listRef.current) {
      listRef.current.scrollTop = 0
    }

    const start = n * constants.LOGS_PER_PAGE
    const end = start + constants.LOGS_PER_PAGE
    setPageLogs(allLogs.slice(start, end))
  }

  function handlePageChange(event, value) {
    setPage(value)
    getNthPage(value - 1)
  }

  function switchIcon(level) {
    switch (level) {
      case constants.INFO:
        return <InfoOutlined color="info" />
      case constants.DEBUG:
        return <BugReport color="warning" />
      case constants.ERROR:
        return <ErrorOutline color="error" />
      default:
        return <QuestionMark />
    }
  }

  function renderLog(log, index) {
    if (!log || !log.timestamp || !log.level || !log.text) {
      return null
    }

    const handleClick = () => {
      setSelectedLog(log)
      setOpenModal(true)
    }

    return (
      <div key={`log-${log.timestamp}`}>
        <ListItem
          disablePadding
          onClick={handleClick}
        >
          <ListItemIcon sx={{justifyContent: 'center'}}>
            {switchIcon(log.level)}
          </ListItemIcon>
          <ListItemText primary={log.text} secondary={log.timestamp} />
        </ListItem>
        <Divider />
      </div>
    )
  }

  function renderSkeletons() {
    return [...Array(constants.LOGS_PER_PAGE)].map((_, index) => (
      <div key={`skeleton-${index}`}>
        <ListItem disablePadding>
          <ListItemIcon sx={{justifyContent: 'center'}}>
            <Skeleton variant="circular" width={25} height={25} />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton variant="text" style={{ width: '60%' }} />}
            secondary={<Skeleton variant="text" style={{ width: '30%' }} />}>
          </ListItemText>
        </ListItem>
        <Divider />
      </div>
    ))
  }

  async function shareLogFile() {
    try {
      const logsBlob = new Blob(
        [JSON.stringify(allLogs, null, 2)],
        { type: 'text/plain' }
      )
      const logsFile = new File(
        [logsBlob],
        `${constants.LOGS_FILE_NAME}.txt`,
        { type: 'text/plain' }
      )

      const data = {
        files: [logsFile],
        title: i18n.t('Logs.SHARE_TITLE'),
        text: i18n.t('Logs.SHARE_TEXT'),
      }

      navigator.share(data)
    } catch (error) {
      Cache.log(constants.ERROR, i18n.t('Logs.SHARE_ERROR'), error.toString())
      Snackbar.enqueue({
        message: i18n.t('Logs.SHARE_ERROR'),
        variant: 'error',
      })
    }
  }

  return (
    <Base showHeader>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Fade in={openModal}>
          <Box sx={styles.modal}>
            {selectedLog && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                  {switchIcon(selectedLog.level)}
                  <Typography variant="body1" sx={{ marginLeft: 1 }}>
                    {selectedLog.text}
                  </Typography>
                </Box>
                <Typography variant="body2">{selectedLog.timestamp}</Typography>
                {selectedLog.data && (
                  <>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Typography variant="body2" component="pre">
                      {JSON.stringify(selectedLog.data, null, 2)}
                    </Typography>
                  </>
                )}
              </>
            )}
          </Box>
        </Fade>
      </Modal>
      <List dense disablePadding style={  styles.list} ref={listRef}>
        {loading
        ? renderSkeletons()
        : pageLogs.map((log, index) => renderLog(log, index))}
        <div style={styles.listPadding} />
      </List>
      <Divider />
      <Pagination
        count={Math.ceil(allLogs.length / constants.LOGS_PER_PAGE)}
        page={page}
        onChange={handlePageChange}
        style={styles.pagination}
      />
      <Button
        color={"primary"}
        variant={"contained"}
        onClick={shareLogFile}
        disabled={loading}
        style={styles.shareButton}
      >
        <IosShare sx={styles.shareIcon} />
      </Button>
    </Base>
  )
}

const styles = {
  list: {
    overscroll: 'contain',
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    maxHeight: '90%',
    height: '90%',
  },
  listPadding: {
    height: '15%',
    minHeight: 0,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    outline: 'none',
    borderRadius: 3,
    padding: 3,
    boxShadow: 24,
    overflow: 'auto',
  },
  pagination: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    height: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shareButton: {
    position: 'absolute',
    right: '5vw',
    bottom: 'calc(5vw + 10%)',
    width: 60,
    height: 60,
    borderRadius: 50,
    minWidth: 0,
    zIndex: 10,
    padding: '0 0 5px 0',
  },
  shareIcon: {
    fontSize: 28,
  },
}

export default Logs