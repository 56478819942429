/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import {
  SwipeableDrawer,
  Typography,
  Button,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import i18n from 'i18n-js'
import VDSDataPage from 'common/VDSDataPage'

const DataDrawer = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false)

  //#region Drawer handlers
  function toggleDrawer(newOpen) {
    setOpen(newOpen)

    newOpen
      ? props.onOpen && props.onOpen()
      : props.onClose && props.onClose()
  }
  
  React.useEffect(() => {
    toggleDrawer(true)
  }, [])
  
  React.useImperativeHandle(ref, () => ({
    toggleDrawer
  }))
  //#endregion

  function Puller() {
    return (
      <div style={styles.bleed}>
        <div style={styles.puller}/>
        <div
          id="invisble"
          style={styles.invisiblePuller}
        />

        <Typography 
        style={styles.pullerText}
        sx={{ p: 2, color: 'text.secondary' }}>
          {i18n.t('DataDrawer.EDGE_TEXT')}
        </Typography>
        <div style={styles.pullerButtonsContainer}>
          <Button
            onClick={() => toggleDrawer(false)}
            variant="text"
            style={{...styles.pullerButtons, marginLeft: 12}}>
            <Close sx={{fontSize: 20}}/>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      disableDiscovery={true}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      anchor="bottom"
      open={open}
      ModalProps={{keepMounted: true}}
      PaperProps={{style: styles.PaperProps}}
      onClose={() => {toggleDrawer(false)}}
      onOpen={() => {toggleDrawer(true)}}
    >
      <Puller />
      <VDSDataPage data={props.data} />
    </SwipeableDrawer>
  )
})

export const drawerBleeding = 60
const styles = {
  PaperProps: {
    overflowY: 'visible',
    height: `calc(95% - ${drawerBleeding}px)`,
    minHeight: `calc(95% - ${drawerBleeding}px)`,
    maxHeight: `calc(95% - ${drawerBleeding}px)`,
  },
  drawerContent: {
    top: drawerBleeding,
    overflow: 'auto'
  },
  bleed: {
    position: 'absolute',
    top: -drawerBleeding,
    height: drawerBleeding,
    visibility: 'visible',
    right: 0,
    left: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    background: 'white',
  },
  puller: {
    width: 64,
    height: 6,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 32px)',
    background: 'lightgrey',
  },
  invisiblePuller: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    touchAction: 'none',
  },
  pullerText: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
  pullerButtonsContainer: {
    position: 'absolute',
    top: 6,
    right: 12,
    padding: 4,
  },
  pullerButtons: {
    borderRadius: 20,
    height: 35,
    width: 35,
    minWidth: 0,
  }
}

export default DataDrawer