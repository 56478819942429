/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { muiTheme as theme } from '../theme'
import { enqueueSnackbar, closeSnackbar } from "notistack"

export function enqueue(props) {
  const { message, ...other } = props
  return enqueueSnackbar(
    props.message,
    {
      ...other,
      action: (key) => (
        <IconButton
          onClick={() => closeSnackbar(key)}
          sx={{ fontSize: 20, color: theme.palette.white.main }}
        >
          <Close />
        </IconButton>
      ),
    })
}

export default {
  enqueue,
}