/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import i18n from 'i18n-js'
import { frTranslation } from './i18n/fr'

i18n.locale = 'fr'

i18n.translations = {
  fr: frTranslation
}

i18n.fallbacks = true

export const appTitle = 'VdsChecker'

export const INFO = 'INFO'
export const DEBUG = 'DEBUG'
export const ERROR = 'ERROR'
export const LOGS_PER_PAGE = 50
export const LOGS_FILE_NAME = 'logs' // without extension

export const CACHE_VALUES = {
  dbName: appTitle,
  defaultExpirationDuration: 1000 * 60 * 60 * 24 * 30,
  tables: {
    logs: {
      name: 'logs',
      key: 'timestamp',
      timestamp: 'timestamp',
      expirationField: 'expirationDate',
      maxCount: 5000
    },
    certificates: {
      name: 'certificates',
      key: 'thumbprint',
      timestamp: 'timestamp',
      expirationField: 'expirationDate',
      maxCount: null,
    },
    preferences: {
      name: 'preferences',
      key: null,
      timestamp: null,
      expirationField: null,
      maxCount: null,
    },
  }
}

export const VDSP13 = {
  INTEGER: 'INTEGER',
  STRING: 'STRING',
  DATE: 'DATE',
  HEX: 'HEX',

  STATUS: {
    VALID: 'VALID',
    INVALID: 'INVALID',
  },
  SUB_INDICATION: {
    UNKNOWN_FEATURE: 'UNKNOWN_FEATURE',
    READ_ERROR: 'READ_ERROR',
    EXPIRED_CERTIFICATE: 'EXPIRED_CERTIFICATE',
    WRONG_FORMAT: 'WRONG_FORMAT',
    UNKNOWN_CERTIFICATE: 'UNKNOWN_CERTIFICATE',
    UNTRUSTED_CERTIFICATE: 'UNTRUSTED_CERTIFICATE',
    INVALID_DOCUMENTTYPE: 'INVALID_DOCUMENTTYPE',
    REVOKED_CERTIFICATE: 'REVOKED_CERTIFICATE',
    INVALID_SIGNATURE: 'INVALID_SIGNATURE',
  },
}
