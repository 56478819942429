/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { IconButton } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

function ReturnArrow(props) {
  const navigate = useNavigate()

  return (
    <IconButton
      color={props.color || "primary"}
      style={props.style}
      onClick={() => {navigate('/')}}
    >
      <ArrowBackIos sx={{...props.sx, fontSize: 25}}/>
    </IconButton>
  )
}

export default ReturnArrow