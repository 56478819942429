/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

export const VDSNC_PoT_msg = {
  $id: "http://namespaces.icao.int/VDS-NC_message_PoT_ICAO.json",
  title: "Message Zone ICAO (PoT)",
  type: "object",
  description: "PoT Message Schema",
  properties: {
    utci: {
      type: "string",
      maxLength: 18
    },
    pid: {
      type: "object",
      properties: {
        n: {
          type: "string",
          maxLength: 39
        },
        dob: {
          type: "string",
          description: "Format YYYY-MM-DD",
          maxLength: 10
        },
        dt: {
          type: "string"
        },
        dn: {
          type: "string",
          maxLength: 24
        }
      },
      required: [
        "n",
        "dob",
        "dt",
        "dn"
      ],
      additionalProperties: false
    },
    sp: {
      type: "object",
      properties: {
        spn: {
          type: "string",
          maxLength: 20
        },
        ctr: {
          type: "string",
          maxLength: 3,
          description: "A three letter code identifying the country of test."
        },
        cd: {
          type: "object",
          properties: {
            p: {
              type: "string",
              maxLength: 19
            },
            e: {
              type: "string"
            },
            a: {
              type: "string"
            }
          },
          required: [
            "p",
            "e",
            "a"
          ]
        }
      },
      required: [
        "spn",
        "ctr",
        "cd"
      ],
      additionalProperties: false
    },
    dat: {
      type: "object",
      properties: {
        sc: {
          type: "string",
          maxLength: 25,
          description: "Refer to rfc3339"
        },
        ri: {
          type: "string",
          maxLength: 25,
          description: "Refer to rfc3339"
        }
      },
      required: [
        "sc",
        "ri"
      ],
      additionalProperties: false
    },
    tr: {
      type: "object",
      properties: {
        tc: {
          type: "string",
          enum: [
            "molecular(PCR)",
            "molecular(other)",
            "antigen",
            "antibody"
          ]
        },
        r: {
          type: "string",
          enum: [
            "normal",
            "abnormal",
            "positive",
            "negative"
          ]
        },
        m: {
          type: "string",
          enum: [
            "nasopharyngeal",
            "oropharyngeal",
            "saliva",
            "blood",
            "other"
          ]
        }
      },
      required: [
        "tc",
        "r"
      ],
      additionalProperties: false
    },
    opt: {
      type: "string",
      maxLength: 20
    }
  },
  required: [
    "pid",
    "sp",
    "dat",
    "tr"
  ],
  additionalProperties: false
}

export const VDSNC_PoV_msg_v1 = {
  $id: "http://namespaces.icao.int/VDS-NC_message_PoV_WHO.json",
  title: "Message Zone WHO (PoV)",
  type: "object",
  description: "PoV Message Schema",
  properties: {
    uvci: {
      type: "string",
      maxLength: 18
    },
    pid: {
      type: "object",
      properties: {
        n: {
          type: "string",
          maxLength: 39
        },
        dob: {
          type: "string",
          maxLength: 10,
          description: "Format YYYY-MM-DD. Mandatory if no UniqueIdentifier is provided."
        },
        i: {
          type: "string",
          maxLength: 11,
          description: "Travel Document Number."
        },
        ai: {
          type: "string",
          maxLength: 24,
          description: "Other document number."
        },
        sex: {
          type: "string",
          maxLength: 1,
          description: "Specific instance of sex information for the vaccinated person."
        }
      },
      required: [
        "n"
      ],
      anyOf: [
        {
          required: [
            "i"
          ]
        },
        {
          required: [
            "dob"
          ]
        }
      ],
      additionalProperties: false
    },
    ve: {
      type: "array",
      items: [
        {
          type: "object",
          properties: {
            des: {
              type: "string",
              maxLength: 6
            },
            nam: {
              type: "string"
            },
            dis : {
              type: "string",
              maxLength: 6
            },
            vd: {
              type: "array",
              items: [
                {
                  type: "object",
                  properties: {
                    dvc: {
                      type: "string",
                      maxLength: 10,
                      description: "Format YYYY-MM-DD"
                    },
                    seq : {
                      type: "integer",
                      exclusiveMaximum: 100 // 2 digits
                    },
                    ctr: {
                      type: "string",
                      maxLength: 3,
                      description: "Doc 9303-3 Country Code"
                    },
                    adm: {
                      type: "string",
                      maxLength: 20,
                      description: " name or identifier of the vaccination facility"
                    },
                    lot: {
                      type: "string",
                      maxLength: 20,
                      description: "Batch number or lot number of vaccination"
                    },
                    dvn: {
                      type: "string",
                      maxLength: 10,
                      description: "Format YYYY-MM-DD"
                    }
                  },
                  required: [
                    "dvc",
                    "seq",
                    "ctr",
                    "adm",
                    "lot"
                  ]
                }
              ]
            }
          },
          required: [
            "des",
            "nam",
            "vd"
          ]
        }
      ]
    }
  },
  required: [
    "uvci",
    "pid",
    "ve"
  ]
}

export const VDSNC_PoV_msg_v2 = {
  $id: "http://namespaces.icao.int/VDS-NC_message_PoV_WHO_V2.json",
  title: "Message Zone WHO (PoV)",
  type: "object",
  description: "PoV Message Schema",
  properties: {
    uvci: {
      type: "string",
      maxLength: 18
    },
    cvf: {
      type: "string",
      maxLength: 10,
      description: "Certificate valid from. Format YYYY-MM-DD."
    },
    cvu: {
      type: "string",
      maxLength: 10,
      description: "Certificate valid until. Format YYYY-MM-DD."
    },
    pid: {
      type: "object",
      properties: {
        n: {
          type: "string",
          maxLength: 39
        },
        dob: {
          type: "string",
          maxLength: 10,
          description: "Format YYYY-MM-DD. Mandatory if no UniqueIdentifier is provided."
        },
        i: {
          type: "string",
          maxLength: 11,
          description: "Travel Document Number."
        },
        ai: {
          type: "string",
          maxLength: 24,
          description: "Other document number."
        },
        sex: {
          type: "string",
          maxLength: 1,
          description: "Specific instance of sex information for the vaccinated person."
        }
      },
      required: [
        "n"
      ],
      anyOf: [
        {
          required: [
            "i"
          ]
        },
        {
          required: [
            "dob"
          ]
        }
      ],
      additionalProperties: false
    },
    ve: {
      type: "array",
      items: [
        {
          type: "object",
          properties: {
            des: {
              type: "string",
              maxLength: 6
            },
            nam: {
              type: "string"
            },
            mfg: {
              type: "string"
            },
            mah: {
              type: "string"
            },
            dis: {
              maxLength: 6,
              type: "string"
            },
            vd: {
              type: "array",
              items: [
                {
                  type: "object",
                  properties: {
                    dvc: {
                      type: "string",
                      maxLength: 10,
                      description: "Format YYYY-MM-DD"
                    },
                    seq: {
                      type: "integer",
                      exclusiveMaximum: 100 // 2 digits
                    },
                    tot: {
                      type: "integer",
                      exclusiveMaximum: 100 // 2 digits
                    },
                    ctr: {
                      type: "string",
                      maxLength: 3,
                      description: "Doc 9303-3 Country Code"
                    },
                    adm: {
                      type: "string",
                      maxLength: 20,
                      description: " name or identifier of the vaccination facility"
                    },
                    lot: {
                      type: "string",
                      maxLength: 20,
                      description: "Batch number or lot number of vaccination"
                    },
                    dvn: {
                      type: "string",
                      maxLength: 10,
                      description: "Format YYYY-MM-DD"
                    }
                  },
                  required: [
                    "dvc",
                    "seq",
                    "ctr",
                    "adm",
                    "lot"
                  ]
                }
              ]
            }
          },
          required: [
            "des",
            "nam",
            "vd"
          ],
          anyOf: [
            {
              required: [
                "mfg"
              ]
            },
            {
              required: [
                "mah"
              ]
            }
          ]
        }
      ]
    },
    opt: {
      type: "string",
      maxLength: 20
    }
  },
  required: [
    "uvci",
    "pid",
    "ve"
  ]
}

export const VDSNC_PoR_msg = {
  $id: "http://namespaces.icao.int/VDS-NC_message_PoR_ICAO.json",
  title: "Message Zone ICAO (PoR)",
  type: "object",
  description: "PoR Message Schema",
  properties: {
    urci: {
      type: "string",
      maxLength: 18
    },
    cvf: {
      type: "string",
      maxLength: 10,
      description: "Certificate valid from. Format YYYY-MM-DD."
    },
    cvu: {
      type: "string",
      maxLength: 10,
      description: "Certificate valid until. Format YYYY-MM-DD."
    },
    pid: {
      type: "object",
      properties: {
        n: {
          type: "string",
          maxLength: 39
        },
        dob: {
          type: "string",
          maxLength: 10,
          description: "Format YYYY-MM-DD"
        },
        dt: {
          type: "string"
        },
        dn: {
          type: "string",
          maxLength: 24
        }
      },
      required: [
        "n",
        "dob",
        "dt",
        "dn"
      ],
      additionalProperties: false
    },
    tr: {
      type: "object",
      properties: {
        sot: {
          type: "string",
          maxLength: 3,
          description: "Three letter code identifying the country of test."
        },
        dnt: {
          type: "string",
          maxLength: 10,
          description: "Date of sample for NAAT test with positive result. Format YYYY-MM-DD."
        }
      },
      required: [
        "sot",
        "dnt"
      ],
      additionalProperties: false
    },
    opt: {
      type: "string",
      maxLength: 20
    }
  },
  required: [
    "urci",
    "pid",
    "tr"
  ]
}

export const VDSNC_DTA_msg = {
  $id: "http://namespaces.icao.int/VDS-NC_message_DTA.json",
  title: "Message Zone DTA",
  type: "object",
  description: "DTA Message Schema",
  properties: {
    dtan: {
      type: "string",
      maxLength: 13
    },
    pi: {
      type: "object",
      properties: {
        n: {
          type: "string",
          maxLength: 39,
          description: "The name of holder"
        },
        pn: {
          type: "string",
          maxLength: 11,
          description: "The number of the passport with which the travel authorization is linked"
        },
        dob: {
          type: "string",
          maxLength: 10,
          description: "Date of birth of holder.ISO8601 YYYY-MM-DD"
        },
        nl: {
          type: "string",
          maxLength: 3,
          description: "The nationality of the holder."
        },
        sex: {
          type: "string",
          maxLength: 1,
          description: "Sex of the holder."
        }
      },
      required: [
        "n",
        "pn",
        "dob"
      ],
      additionalProperties: false
    },
    dta: {
      type: "object",
      properties: {
        poi: {
          type: "string",
          maxLength: 15,
          description: "Post/location (usually a city) where the DTA is issued."
        },
        vf: {
          type: "string",
          maxLength: 10,
          description: "The date of issue of the DTA which indicates the first date from which the authorization can be used to seek entry."
        },
        vu: {
          type: "string",
          maxLength: 10,
          description: "The date of expiry of the authorization which indicates the last day on which the DTA can be used to seek entry."
        },
        dos: {
          type: "string",
          maxLength: 6,
          description: "The number of days, months or years during which the DTA holder may stay in the territory. YYDDMM"
        },
        noe: {
          type: "string",
          maxLength: 1,
          description: "The number of allowed entries to the territory."
        },
        tcc: {
          type: "string",
          maxLength: 46,
          description: "The issuing State’s indication of the type and/or class of DTA granted in accordance with the law/practice of that State; The broad categorization of the type of DTA granted, e.g. visitor/resident/ temporary resident/student/diplomat, etc., in accordance with the law/practice of the issuing State; any limitations on the territorial validity of the travel authorization."
        },
        ai: {
          type: "string",
          maxLength: 100,
          description: "Any necessary endorsements as to entitlements which attach to the DTA. "
        }
      },
      required: [
        "poi",
        "vf",
        "vu",
        "noe",
        "tcc"
      ],
      additionalProperties: false
    }
  },
  required: [
    "dtan",
    "pi",
    "dta"
  ],
  additionalProperties: false
}

/*
 * Changes made to the original schema:
 * - changed 'oneOf' to 'anyOf' in sig because sigv2 matches every sigv1
 * - added required: ["data"] to the root object, no sense not having at least a data field
 */
export const VDSNC = {
  $id: "http://namespaces.icao.int/VDS-NC.json",
  title: "VDS-NC",
  type: "object",
  description: "VDS-NC Schema",
  properties: {
    data: {
      type: "object",
      properties: {
        hdr: {
          $ref: "#/$defs/hdr"
        },
        msg: {},
        additionalProperties: false
      },
      required: [
        "hdr",
        "msg"
      ],
      additionalProperties: false
    },
    sig: {
      anyOf: [
        {
          $ref: "#/$defs/sig"
        },
        {
          $ref: "#/$defs/sigv2"
        }
      ]
    }
  },
  required: [
    "data",
  ],
  $defs: {
    hdr: {
      $id: "http://namespaces.icao.int/VDS-NC_header.json",
      title: "Header",
      type: "object",
      description: "Header Schema",
      properties: {
        t: {
          type: "string",
          /* not only icao use cases
          enum: [
            "icao.test",
            "icao.vacc",
            "icao.rcvy",
            "icao.dta"
          ]
           */
        },
        v: {
          type: "integer"
        },
        is: {
          type: "string"
        }
      },
      required: [
        "t",
        "v",
        "is"
      ],
      additionalProperties: false
    },
    sig: {
      $id: "http://namespaces.icao.int/VDS-NC_signature.json",
      title: "Signature Zone",
      type: "object",
      description: "Signature Schema",
      properties: {
        alg: {
          type: "string",
          description: "The signature algorithm used to produce the signature"
        },
        cer: {
          type: "string",
          description: "X.509 signer certificate in base64url encoding"
        },
        sigvl: {
          type: "string",
          description: "Signature value signed over the Data in base64url encoding"
        }
      },
      required: [
        "alg",
        "cer",
        "sigvl"
      ],
      additionalProperties: false
    },
    sigv2: {
      $id: "http://namespaces.icao.int/VDS-NC_signatureV2.json",
      title: "Signature Zone",
      type: "object",
      description: "Signature Schema",
      properties: {
        alg: {
          type: "string",
          description: "The signature algorithm used to produce the signature"
        },
        cer: {
          type: "string",
          description: "X.509 signer certificate in base64url encoding"
        },
        cref: {
          type: "string",
          maxLength: 42,
          description: " The identifier for the barcode signer. It is made up of 2 letter country code + serial number of the barcode signer."
        },
        sigvl: {
          type: "string",
          description: "Signature value signed over the Data in base64url encoding"
        }
      },
      required: [
        "alg",
        "sigvl"
      ],
      anyOf: [
        {
          required: [
            "cer"
          ]
        },
        {
          required: [
            "cref"
          ]
        }
      ],
      additionalProperties: false
    }
  }
}