/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import moment from 'moment'
import Utils from 'common/Utils'
import i18n from 'i18n-js'
import { VDSP13 } from './constants'
import { VDSError } from 'VDSp13'
import { dateDisplayFormat } from 'theme'

//#region Document Features Definitions
// Document feature definition references.
const ICAO_VISA = 0x5D
const ICAO_ETD = 0x5E
const ARRIVAL_ATTESTATION = 0xFD
const SOCIAL_INSURANCE_CARD = 0xFC
const RESIDENCE_PERMIT = 0xFB
const SUPPlEMENTARY_SHEET = 0xFA
const ADDRESS_STICKER = 0xF9
const PLACE_OF_RESIDENCE_STICKER = 0xF8

// Schemas for known document feature definitions with JSON-Schema-like rules.
export const schemas = {
  [0x01]: {
    [ICAO_VISA]: {
      version: 0x03,
      properties: {
        [i18n.t('VDSp13decode.MRZ_MRV_A')]: { tag: 0x01, minLength: 48, maxLength: 48, decodeFunction: decodeMRZ },
        [i18n.t('VDSp13decode.MRZ_MRV_B')]: { tag: 0x02, minLength: 44, maxLength: 44, decodeFunction: decodeMRZ },
        [i18n.t('VDSp13decode.NUMBER_OF_ENTRIES')]: { tag: 0x03, minLength: 1, maxLength: 1, decodeFunction: decodeInteger },
        [i18n.t('VDSp13decode.DURATION_OF_STAY')]: { tag: 0x04, minLength: 3, maxLength: 3, decodeFunction: decodeDurationOfStay },
        [i18n.t('VDSp13decode.PASSPORT_NUMBER')]: { tag: 0x05, minLength: 6, maxLength: 6, decodeFunction: decodeString },
        [i18n.t('VDSp13decode.VISA_TYPE')]: { tag: 0x06, minLength: 1, maxLength: 4, decodeFunction: decodeBinary },
        [i18n.t('VDSp13decode.ADDITIONAL_FEATURES')]: { tag: 0x07, minLength: 0, maxLength: 254, decodeFunction: decodeBinary },
      },
      required: [
        i18n.t('VDSp13decode.DURATION_OF_STAY'),
        i18n.t('VDSp13decode.PASSPORT_NUMBER')
      ],
      oneOf: [
        i18n.t('VDSp13decode.MRZ_MRV_A'),
        i18n.t('VDSp13decode.MRZ_MRV_B')
      ],
      additionalProperties: false,
    }
  },
  [0x02]: {
    [ARRIVAL_ATTESTATION]: {
      version: 0x02,
      properties: {
        [i18n.t('VDSp13decode.MRZ')]: { tag: 0x02, minLength: 48, maxLength: 48, decodeFunction: decodeMRZ },
        [i18n.t('VDSp13decode.AZR_NUMBER')]: { tag: 0x03, minLength: 8, maxLength: 8, decodeFunction: decodeString },
      },
      required: [
        i18n.t('VDSp13decode.MRZ'),
        i18n.t('VDSp13decode.AZR_NUMBER')
      ],
    }
  },
  [0x03]: {
    [ICAO_ETD]: {
      version: 0x03,
      properties: {
        [i18n.t('VDSp13decode.MRZ')]: { tag: 0x02, minLength: 48, maxLength: 48, decodeFunction: decodeMRZ },
      },
      required: [i18n.t('VDSp13decode.MRZ')],
    }
  },
  [0x04]: {
    [SOCIAL_INSURANCE_CARD]: {
      version: 0x02,
      properties: {
        [i18n.t('VDSp13decode.SOCIAL_INSURANCE_NUMBER')]: { tag: 0x01, minLength: 8, maxLength: 8, decodeFunction: decodeString },
        [i18n.t('VDSp13decode.SURNAME')]: { tag: 0x02, minLength: 1, maxLength: 90, decodeFunction: decodeUTF8 },
        [i18n.t('VDSp13decode.FIRST_NAME')]: { tag: 0x03, minLength: 1, maxLength: 90, decodeFunction: decodeUTF8 },
        [i18n.t('VDSp13decode.NAME_AT_BIRTH')]: { tag: 0x04, minLength: 1, maxLength: 90, decodeFunction: decodeUTF8 },
      },
      required: [
        i18n.t('VDSp13decode.SOCIAL_INSURANCE_NUMBER'),
        i18n.t('VDSp13decode.SURNAME'),
        i18n.t('VDSp13decode.FIRST_NAME'),
      ]
    }
  },
  [0x06]: {
    [RESIDENCE_PERMIT]: {
      version: 0x03,
      properties: {
        [i18n.t('VDSp13decode.MRZ')]: { tag: 0x02, minLength: 48, maxLength: 48, decodeFunction: decodeMRZ },
        [i18n.t('VDSp13decode.PASSPORT_NUMBER')]: { tag: 0x03, minLength: 6, maxLength: 6, decodeFunction: decodeString },
      },
      required: [
        i18n.t('VDSp13decode.MRZ'),
        i18n.t('VDSp13decode.PASSPORT_NUMBER')
      ],
    },
    [SUPPlEMENTARY_SHEET]: {
      version: 0x03,
      properties: {
        [i18n.t('VDSp13decode.MRZ_OF_RESIDENCE_PERMIT_DOCUMENT')]: { tag: 0x04, minLength: 48, maxLength: 48, decodeFunction: decodeMRZ },
        [i18n.t('VDSp13decode.SUPPLEMENTARY_SHEETS_NUMBER')]: { tag: 0x05, minLength: 6, maxLength: 6, decodeFunction: decodeString },
      },
      required: [
        i18n.t('VDSp13decode.MRZ_OF_RESIDENCE_PERMIT_DOCUMENT'),
        i18n.t('VDSp13decode.SUPPLEMENTARY_SHEETS_NUMBER')
      ]
    },
  },
  [0x08]: {
    [ADDRESS_STICKER]: {
      version: 0x03,
      properties: {
        [i18n.t('VDSp13decode.DOCUMENT_NUMBER')]: { tag: 0x01, minLength: 6, maxLength: 6, decodeFunction: decodeString },
        [i18n.t('VDSp13decode.OFFICIAL_MUNICIPALITY_CODE_NUMBER')]: { tag: 0x02, minLength: 6, maxLength: 6, decodeFunction: decodeString },
        [i18n.t('VDSp13decode.RESIDENTIAL_ADDRESS')]: { tag: 0x03, minLength: 6, maxLength: 18, decodeFunction: decodeString },
      },
      required: [
        i18n.t('VDSp13decode.DOCUMENT_NUMBER'),
        i18n.t('VDSp13decode.OFFICIAL_MUNICIPALITY_CODE_NUMBER'),
        i18n.t('VDSp13decode.RESIDENTIAL_ADDRESS')
      ]}
  },
  [0x0A]: {
    [PLACE_OF_RESIDENCE_STICKER]: {
      version: 0x03,
      properties: {
        [i18n.t('VDSp13decode.DOCUMENT_NUMBER')]: { tag: 0x01, minLength: 6, maxLength: 6, decodeFunction: decodeString },
        [i18n.t('VDSp13decode.OFFICIAL_MUNICIPAL_CODE_NUMBER')]: { tag: 0x02, minLength: 6, maxLength: 6, decodeFunction: decodeString },
        [i18n.t('VDSp13decode.POSTAL_CODE')]: { tag: 0x03, minLength: 4, maxLength: 4, decodeFunction: decodeString },
      },
      required: [
        i18n.t('VDSp13decode.DOCUMENT_NUMBER'),
        i18n.t('VDSp13decode.OFFICIAL_MUNICIPAL_CODE_NUMBER'),
        i18n.t('VDSp13decode.POSTAL_CODE')
      ]
    }
  }
}

// Get the feature definition schema for given category and feature reference.
function getDocumentFeatureDefinition(docTypeCategory, docFeatDefRef) {
  const featureCategory = schemas[docTypeCategory]
  if (!featureCategory) {
    throw new VDSError(
      i18n.t('VDSp13decode.UNKNOWN_DOCUMENT_TYPE_CATETORY'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  const featureSchema = featureCategory[docFeatDefRef]
  if (!featureSchema) {
    throw new VDSError(
      i18n.t('VDSp13decode.UNKNOWN_DOCUMENT_FEATURE_DEFINITION_REFERENCE'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  return featureSchema
}

// Validate and parse the document features in place.
export function parseDocumentFeatures(vds) {
  const featureSchema = getDocumentFeatureDefinition(
    vds.header.documentTypeCategory,
    vds.header.documentFeatureDefinitionReference
  )
  const documentFeatures = {}

  if (featureSchema.version && featureSchema.version !== vds.header.version) {
    throw new VDSError(
      i18n.t('VDSp13decode.INVALID_VERSION'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  for (const [featureName, featureSpec] of Object.entries(featureSchema.properties)) {
    const entry = vds.message.rawDocumentFeatures[featureSpec.tag]
    if (!entry) {
      if (featureSchema.required.includes(featureName)) {
        throw new VDSError(
          i18n.t('VDSp13decode.DOCUMENT_FEATURE_MISSING'),
          VDSP13.SUB_INDICATION.WRONG_FORMAT,
          VDSP13.STATUS.INVALID
        )
      }
      continue
    }
    if ((featureSpec.minLength && entry.length < featureSpec.minLength)
     || (featureSpec.maxLength && entry.length > featureSpec.maxLength)) {
      throw new VDSError(
        i18n.t('VDSp13decode.DOCUMENT_FEATURE_INVALID_LENGTH'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }
    documentFeatures[featureName] = featureSpec.decodeFunction(entry.value)
  }

  if (featureSchema.oneOf) {
    const keys = Object.keys(documentFeatures)
    const matchLength = keys.filter(key => featureSchema.oneOf.includes(key)).length
    if (matchLength !== 1) {
      throw new VDSError(
        i18n.t('VDSp13decode.INVALID_FORMAT'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }
  }

  if (featureSchema.anyOf) {
    const keys = Object.keys(documentFeatures)
    const matchLength = keys.filter(key => featureSchema.anyOf.includes(key)).length
    if (matchLength === 0) {
      throw new VDSError(
        i18n.t('VDSp13decode.INVALID_FORMAT'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }
  }

  if (featureSchema.allOf) {
    const keys = Object.keys(documentFeatures)
    const matchLength = keys.filter(key => featureSchema.allOf.includes(key)).length
    if (matchLength !== featureSchema.allOf.length) {
      throw new VDSError(
        i18n.t('VDSp13decode.INVALID_FORMAT'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }
  }

  const knownFeaturesNumber = Object.keys(documentFeatures).length
  const messageFeaturesNumber = Object.keys(vds.message.rawDocumentFeatures).length

  if (messageFeaturesNumber > knownFeaturesNumber) {
    throw new VDSError(
      i18n.t('VDSp13decode.UNKNOWN_DOCUMENT_FEATURE'), VDSP13.SUB_INDICATION.UNKNOWN_FEATURE,
    )
  }
  
  vds.message.documentFeatures = documentFeatures
}
//#endregion

//#region Decode functions

/**
 * Decode a string encoded in C40
 * "C40 Encoding of Strings (Normative)" is described in BSI-TR-03137 Part 1, Annex B:
 * https://www.bsi.bund.de/SharedDocs/Downloads/EN/BSI/Publications/TechGuidelines/TR03137/BSI-TR-03137_Part1.pdf
 *
 * @param {Uint8Array} bytes The byte array to decode.
 * @returns {string}         The decoded string.
 */
export function decodeString(bytes) {
  // Get the corresponding ASCII character for a given C40 value.
  function C40toChar(v) {
    switch (v) {
      case 0: return '' // Shift 1 (?)
      case 1: return '' // Shift 2 (?)
      case 2: return '' // Shift 3 (?)
      case 3: return ' '
      case 4: return '0'
      case 5: return '1'
      case 6: return '2'
      case 7: return '3'
      case 8: return '4'
      case 9: return '5'
      case 10: return '6'
      case 11: return '7'
      case 12: return '8'
      case 13: return '9'
      case 14: return 'A'
      case 15: return 'B'
      case 16: return 'C'
      case 17: return 'D'
      case 18: return 'E'
      case 19: return 'F'
      case 20: return 'G'
      case 21: return 'H'
      case 22: return 'I'
      case 23: return 'J'
      case 24: return 'K'
      case 25: return 'L'
      case 26: return 'M'
      case 27: return 'N'
      case 28: return 'O'
      case 29: return 'P'
      case 30: return 'Q'
      case 31: return 'R'
      case 32: return 'S'
      case 33: return 'T'
      case 34: return 'U'
      case 35: return 'V'
      case 36: return 'W'
      case 37: return 'X'
      case 38: return 'Y'
      case 39: return 'Z'
      default:
        throw new VDSError(
          i18n.t('VDSp13decode.C40_INVALID_VALUE'),
          VDSP13.SUB_INDICATION.WRONG_FORMAT,
          VDSP13.STATUS.INVALID
        )
    }
  }

  if (bytes.length % 2 !== 0) {
    throw new VDSError(
      i18n.t('VDSp13decode.C40_ODD_LENGTH'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  let resultString = ''

  for (let i = 0; i < bytes.length; i += 2) {
    if (bytes[i] === 0xFE) {
      const asciiValue = bytes[i + 1] - 1
      if (asciiValue < 0 || asciiValue > 127) {
        throw new VDSError(
          i18n.t('VDSp13display.C40_INVALID_ASCII'),
          VDSP13.SUB_INDICATION.WRONG_FORMAT,
          VDSP13.STATUS.INVALID
        )
      }
      resultString += String.fromCharCode(asciiValue)
      continue
    }

    const v16 = (bytes[i] << 8) | bytes[i + 1]
    const u1 = ((v16 - 1) / 1600) | 0
    const u2 = ((v16 - (u1 * 1600) - 1) / 40) | 0
    const u3 = v16 - (u1 * 1600) - (u2 * 40) - 1

    resultString += C40toChar(u1)
    resultString += C40toChar(u2)
    resultString += C40toChar(u3)
  }

  return resultString
}

/**
 * Decode a date from a 3-byte array as described in section 2.3.1 of the ICAO
 * Doc 9303 Part 13.
 * @param {Uint8Array} date The date as a 3-byte array.
 * @returns {string}        The decoded date as a string.
 */
export function decodeDate(bytes) {
  if (bytes.length !== 3) {
    throw new VDSError(
      i18n.t('VDSp13decode.DATE_INVALID_LENGTH'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  const value = (bytes[0] << 16) | (bytes[1] << 8) | bytes[2]
  const dateString = value.toString().padStart(8, '0')
  const date =  moment(dateString, 'MMDDYYYY')

  if (!date.isValid()) {
    throw new VDSError(
      i18n.t('VDSp13decode.DATE_INVALID'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  return date.format(dateDisplayFormat)
}

/**
 * Decode a BER/DER encoded integer from a byte array.
 * @param {Uint8Array} bytes The byte array to decode.
 * @returns {number}         The decoded integer.
 */
export function decodeInteger(bytes) {
  // check for maximum length
  if (bytes.length > 4) {
    throw new VDSError(
      i18n.t('VDSp13decode.INTEGER_INVALID_LENGTH'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  let value = BigInt(0)
  for (const byte of bytes) {
    value = (value << BigInt(8)) | BigInt(byte)
  }

  // Check if MSB is set and if so, convert to negative number.
  if (bytes[0] & 0x80) {
    value = value - (BigInt(1) << BigInt(bytes.length * 8))
  }

  return Number(value)
}

/**
 * Decode a byte array as a hex string.
 * @param {Uint8Array} bytes The byte array to decode.
 * @returns {string}         The decoded hex string.
 */
export function decodeBinary(bytes) {
  return Utils.arrayBufferToHex(bytes)
}

/**
 * Decode a MRZ string from a byte array.
 * @param {Uint8Array} bytes The byte array to decode.
 * @returns {string}         The decoded MRZ string.
 */
export function decodeMRZ(bytes) {
  return decodeString(bytes).replace(/ /g, '<')
}

export function decodeUTF8(bytes) {
  function pad(n) {
    return n.length < 2 ? "0" + n : n;
  }

  var buffer = "";
  for (var i = 0, len = bytes.length; i < len; ++i) {
    buffer += ("%" + pad(bytes[i].toString(16)))
  }

  return decodeURIComponent(buffer);
}

/**
 * Decode a duration of stay from a 3-byte array as described in section 9.1.3,
 * Table 1 of document 9303 part 7.
 * @param {Uint8Array} bytes The duration of stay as a 3-byte array.
 * @returns {string}         The decoded duration of stay as a string.
 */
export function decodeDurationOfStay(bytes) {
  if (bytes.length !== 3) {
    throw new VDSError(
      i18n.t('VDSp13decode.DURATION_OF_STAY_INVALID_LENGTH'),
      VDSP13.SUB_INDICATION.WRONG_FORMAT,
      VDSP13.STATUS.INVALID
    )
  }

  if (bytes[0] === 0 && bytes[1] === 0 && bytes[2] === 0) {
    return "The valid until field of the MRZ denotes the last day on which the visa holder may stay in the country for which the visa was issued."
  }

  if (bytes[0] === 0xFF && bytes[1] === 0xFF && bytes[2] === 0xFF) {
    return "The valid until field of the MRZ denotes the last day on which the visa holder may seek entry at the border for which the visa was issued. The duration of stay is determined by the authorities at the time of entry at the border."
  }

  if (bytes[0] === 0xFE && bytes[1] === 0xFE && bytes[2] === 0xFE) {
    return "Transit Visa that does not include permission to enter the country of transit."
  }

  const days = bytes[0];
  const months = bytes[1];
  const years = bytes[2];

  const durationOfStay = `${days} days, ${months} months, ${years} years`;

  return durationOfStay;
}
//#endregion