/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Collapse,
  Grid,
  Card,
  CardContent,
} from '@mui/material'
import {
  CheckCircle,
  Error,
  InfoOutlined,
  Phone,
  Email,
  Place,
  WorkspacePremium,
} from '@mui/icons-material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { muiTheme as theme } from 'theme'
import i18n from 'i18n-js'

export function RawPage({ vds }) {
  const [expandedItems, setExpandedItems] = React.useState(getIDs(vds.content, 'tree-'))

  function buildTree(data, parentId, shouldTranslate) {
    return (
      <>
        {Object.entries(data).map(([key, value], index) => {
          const translation = i18n.lookup(`VDSNC.${key.toUpperCase()}`)
          key = shouldTranslate && translation ? translation : key
          const id = parentId + key + index + '/'
          return typeof value === 'object'
            ? <TreeItem itemId={id} key={id} label={key} style={styles.treeItem}>
              {buildTree(value, id, shouldTranslate)}
            </TreeItem>
            : <TreeItem itemId={id} key={id} label={`${key}: ${value}`} style={styles.treeItem}/>
        })}
      </>
    )
  }
  
  function getIDs(data, parentId) {
    if (typeof data !== 'object') {
      return []
    }

    const expandedIDs = []
    Object.entries(data).map(([key, value], index) => {
      const id = parentId + key + index + '/'
      expandedIDs.push(id)
      expandedIDs.push(...getIDs(value, id))
    })
  
    return expandedIDs
  }

  const handleItemExpansionToggle = (event, itemId, isExpanded) => {
    setExpandedItems(isExpanded
      ? [...expandedItems, itemId]
      : expandedItems.filter((id) => id !== itemId)
    )
  }

  React.useEffect(() => {
     setExpandedItems(getIDs(vds.content, 'tree-'))
  }, [vds])

  // TODO: translate keys or keep raw?
  const shouldTranslate = vds.isPrettyDisplayable

  return (
    <SimpleTreeView
      id="data-tree"
      expandedItems={expandedItems}
      onItemExpansionToggle={handleItemExpansionToggle}
    >
      {buildTree(vds.content, 'tree-', shouldTranslate)}
    </SimpleTreeView>
  )
}

export function PrettyPage({ vds }) {
  //#region PoV, PoR, PoT, DTA
  function PoV({vds}) {
    /**
     * Fields to display (v2):
     * - unique vaccination certificate identifier
     * - certificate valid from (optional)
     * - certificate valid until (optional)
     * - personal information:
     *   - name
     *   - sex
     *   - date of birth (conditional, required if uid / travel document number is not present)
     *   - travel document number (conditional)
     *   - additional identifiier (optional)
     * - vaccination events:
     *   - vaccine / prophylaxis
     *   - vaccine brand
     *   - vaccine manufacturer (conditional, required if no mah)
     *   - vaccine market authorization holder (conditional, required if no mfg)
     *   - disease targeted  
     *   - vaccination details:
     *     - date of vaccination
     *     - dose number
     *     - total doses (optional)
     *     - country of vaccination
     *     - administering centre
     *     - vaccine batch number
     *     - due date of next dose (optional)
     * - optional data (optional)
     * 
     * v1 differences:
     * - no vaccine manufacturer
     * - no vaccine market authorization holder
     * - no dose number
     * - no total doses
     */

    const uvci = vds?.content?.data?.msg?.uvci
    const cvf = vds?.content?.data?.msg?.cvf // optional
    const cvu = vds?.content?.data?.msg?.cvu // optional
    const pid = {
      n: vds?.content?.data?.msg?.pid?.n,
      sex: vds?.content?.data?.msg?.pid?.sex, // recommended (optional)
      dob: vds?.content?.data?.msg?.pid?.dob, // conditional
      i: vds?.content?.data?.msg?.pid?.i, // conditional
      ai: vds?.content?.data?.msg?.pid?.ai, // optional
    }
    let ve = []
    for (let i = 0; i < vds?.content?.data?.msg?.ve.length; i++) {
      const event = vds?.content?.data?.msg?.ve[i]
      let vd = []
      for (let j = 0; j < event.vd.length; j++) {
        vd.push({
          dvc: event.vd[j].dvc,
          seq: event.vd[j].seq,
          tot: event.vd[j].tot, // optional
          ctr: event.vd[j].ctr,
          adm: event.vd[j].adm,
          lot: event.vd[j].lot,
          dvn: event.vd[j].dvn,
        })
      }
      ve.push({
        des: event.des,
        nam: event.nam,
        mfg: event.mfg, // conditional
        mah: event.mah, // conditional
        dis: event.dis, // recommended (optional)
        vd: vd,
      })
    }
    const opt = vds?.content?.data?.msg?.opt // optional

    return (
      <>
        <Grid container spacing={2}>
          <Item name value={pid.n} xs={12} />
          <Item label={i18n.t('VDSNCdisplay.DATE_OF_BIRTH')} value={pid.dob || '?'} xs={6} />
          <Item label={i18n.t('VDSNCdisplay.SEX')} value={pid.sex || '?'} xs={6} />
          <Item label={i18n.t('VDSNCdisplay.TRAVEL_DOCUMENT_NUMBER')} value={pid.i || '?'} xs={6} />
          {pid.ai && <Item label={i18n.t('VDSNCdisplay.ADDITIONAL_IDENTIFIER')} value={pid.ai} xs={6} />}
          <Item title value={i18n.t('VDSNCdisplay.VACCINATION_EVENTS')} xs={12} />
          {ve.map((event, index) => (
            <Grid item xs={12} key={`ve-${index}`}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={0.5}>
                    <Item inline label={i18n.t('VDSNCdisplay.VACCINE_PROPHYLAXIS')} value={event.des} xs={12} />
                    <Item inline label={i18n.t('VDSNCdisplay.VACCINE_BRAND')} value={event.nam} xs={12} />
                    {event.mfg && <Item inline label={i18n.t('VDSNCdisplay.VACCINE_MANUFACTURER')} value={event.mfg} xs={12} />}
                    {event.mah && <Item inline label={i18n.t('VDSNCdisplay.VACCINE_MAH')} value={event.mah} xs={12} />}
                    {event.dis && <Item inline label={i18n.t('VDSNCdisplay.DISEASE_AGENT')} value={event.dis} xs={12} />}
                    {event.vd.map((dose, index) => (
                      <React.Fragment key={`vd-${index}`} >
                        <Grid item xs={12}><Divider variant="middle" style={styles.vdDivider} /></Grid>
                        <Item inline label={i18n.t('VDSNCdisplay.DATE_OF_VACCINATION')} value={dose.dvc} xs={12} />
                        <Item inline label={i18n.t('VDSNCdisplay.DOSE_NUMBER')} value={dose.seq} xs={12} />
                        {dose.tot && <Item inline label={i18n.t('VDSNCdisplay.TOTAL_DOSES')} value={dose.tot} xs={12} />}
                        <Item inline label={i18n.t('VDSNCdisplay.COUNTRY_OF_VACCINATION')} value={dose.ctr} xs={12} />
                        <Item inline label={i18n.t('VDSNCdisplay.ADMINISTERING_CENTRE')} value={dose.adm} xs={12} />
                        <Item inline label={i18n.t('VDSNCdisplay.VACCINE_BATCH_NUMBER')} value={dose.lot} xs={12} />
                        {dose.dvn && <Item inline label={i18n.t('VDSNCdisplay.DUE_DATE_NEXT_DOSE')} value={dose.dvn} xs={12} />}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Item inline label={i18n.t('VDSNCdisplay.UVCI')} value={uvci} xs={12} />
            {cvf && <Item inline label={i18n.t('VDSNCdisplay.VALID_FROM')} value={cvf} xs={12} />}
            {cvu && <Item inline label={i18n.t('VDSNCdisplay.VALID_UNTIL')} value={cvu} xs={12} />}
          </Grid>
          {opt && <Item label={i18n.t('VDSNCdisplay.OPTIONAL_DATA')} value={opt} xs={12} />}
        </Grid>
      </>
    )
  }

  function PoR({vds}) {
    /**
     * Fields to display:
     * - unique recovery certificate identifier
     * - certificate valid from (optional)
     * - certificate valid until (optional)
     * - personal information:
     *   - name
     *   - date of birth
     *   - id document type
     *   - id document number
     * - test result:
     *   - country of test
     *   - date of first positive naat test result
     * - optional data (optional)
     */

    const urci = vds?.content?.data?.msg?.urci
    const cvf = vds?.content?.data?.msg?.cvf // optional
    const cvu = vds?.content?.data?.msg?.cvu // optional
    const pid = {
      n: vds?.content?.data?.msg?.pid?.n,
      dob: vds?.content?.data?.msg?.pid?.dob,
      dt: vds?.content?.data?.msg?.pid?.dt,
      dn: vds?.content?.data?.msg?.pid?.dn,
    }
    const tr = {
      sot: vds?.content?.data?.msg?.tr?.sot,
      dnt: vds?.content?.data?.msg?.tr?.dnt,
    }
    const opt = vds?.content?.data?.msg?.opt // optional

    return (
      <Grid container spacing={2}>
        <Item name value={pid.n} xs={12} />
        <Item label={i18n.t('VDSNCdisplay.DATE_OF_BIRTH')} value={pid.dob} xs={4} />
        <Item label={i18n.t('VDSNCdisplay.DOCUMENT_TYPE')} value={pid.dt} xs={3} />
        <Item label={i18n.t('VDSNCdisplay.DOCUMENT_NUMBER')} value={pid.dn} xs={5} />
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
            <Grid container spacing={1}>
              <Item title value={i18n.t('VDSNCdisplay.POR_TITLE')} xs={12} />
              <Item label={i18n.t('VDSNCdisplay.COUNTRY_OF_ISSUE')} value={tr.sot} xs={12} />
              <Item label={i18n.t('VDSNCdisplay.DATE_OF_FIRST_POSITIVE')} value={tr.dnt} xs={12} />
            </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Item inline label={i18n.t('VDSNCdisplay.URCI')} value={urci} xs={12} />
          {cvf && <Item inline label={i18n.t('VDSNCdisplay.VALID_FROM')} value={cvf} xs={12} />}
          {cvu && <Item inline label={i18n.t('VDSNCdisplay.VALID_UNTIL')} value={cvu} xs={12} />}
        </Grid>
        {opt && <Item label={i18n.t('VDSNCdisplay.OPTIONAL_DATA')} value={opt} xs={12} />}
      </Grid>
    )
  }

  function PoT({vds}) {
    /**
     * Fields to display:
     * - unique test certificate identifier
     * - personal information:
     *   - name
     *   - date of birth
     *   - id document type
     *   - id document number
     * - service provider
     *   - name
     *   - country
     *   - contact details
     *     - phone number
     *     - email address
     *     - address
     * - date and time of specimen collection
     * - date and time of report issuance
     * - test result:
     *   - type of test conducted
     *   - result
     *   - sampling method (optional)
     * - optional data (optional)
     */

    const utci = vds?.content?.data?.msg?.utci // conditional
    const pid = {
      n: vds?.content?.data?.msg?.pid?.n,
      dob: vds?.content?.data?.msg?.pid?.dob,
      dt: vds?.content?.data?.msg?.pid?.dt,
      dn: vds?.content?.data?.msg?.pid?.dn,
    }
    const dat = {
      sc: vds?.content?.data?.msg?.dat?.sc,
      ri: vds?.content?.data?.msg?.dat?.ri,
    }
    const tr = {
      tc: vds?.content?.data?.msg?.tr?.tc,
      r: vds?.content?.data?.msg?.tr?.r,
      m: vds?.content?.data?.msg?.tr?.m, // optional
    }
    const sp = {
      spn: vds?.content?.data?.msg?.sp?.spn,
      ctr: vds?.content?.data?.msg?.sp?.ctr,
      cd: {
        p: vds?.content?.data?.msg?.sp?.cd?.p,
        e: vds?.content?.data?.msg?.sp?.cd?.e,
        a: vds?.content?.data?.msg?.sp?.cd?.a,
      }
    }
    const opt = vds?.content?.data?.msg?.opt // optional

    return (
      <Grid container spacing={2}>
        <Item name value={pid.n} xs={12} />
        <Item label={i18n.t('VDSNCdisplay.DATE_OF_BIRTH')} value={pid.dob} xs={4} />
        <Item label={i18n.t('VDSNCdisplay.DOCUMENT_TYPE')} value={pid.dt} xs={3} />
        <Item label={i18n.t('VDSNCdisplay.DOCUMENT_NUMBER')} value={pid.dn} xs={5} />
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Item label={i18n.t('VDSNCdisplay.SERVICE_PROVIDER')} value={`${sp.spn} (${sp.ctr})`} xs={12} />
                <Item label={i18n.t('VDSNCdisplay.CONTACT_DETAILS')} xs={12} >
                  <Grid item xs={12}>
                    <Phone style={styles.contactIcon} />
                    <Typography display="inline" variant="body1">{sp.cd.p}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Email style={styles.contactIcon} />
                    <Typography display="inline" variant="body1">{sp.cd.e}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Place style={styles.contactIcon} />
                    <Typography display="inline" variant="body1">{sp.cd.a}</Typography>
                  </Grid>
                </Item>
                <Item label={i18n.t('VDSNCdisplay.DATE_SPECIMEN_COLLECTION')} value={dat.sc} xs={12} />
                <Item label={i18n.t('VDSNCdisplay.DATE_REPORT_ISSUANCE')} value={dat.ri} xs={12} />
                <Item label={i18n.t('VDSNCdisplay.TEST_RESULT')} xs={12} >
                  <Item label={i18n.t('VDSNCdisplay.TEST_TYPE')} value={tr.tc} xs={12} />
                  <Item label={i18n.t('VDSNCdisplay.RESULT')} value={tr.r} xs={12} />
                  {tr.m && <Item label={i18n.t('VDSNCdisplay.SAMPLING_METHOD')} value={tr.m} xs={12} />}
                </Item>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {utci && <Item inline label={i18n.t('VDSNCdisplay.UTCI')} value={utci} xs={12} />}
        {opt && <Item label={i18n.t('VDSNCdisplay.OPTIONAL_DATA')} value={opt} xs={12} />}
      </Grid>
    )
  }

  function DTA({vds}) {
    /**
     * Fields to display:
     * - name
     * - date of birth
     * - nationality (not required)
     * - passport number
     * - sex (not required)
     * 
     * - dta number
     * - place of issue
     * - valid from
     * - valid until
     * - duration of stay (not required)
     * - number of entries
     * - visa type/class/category
     * - additional information (not required)
     */

    const pid = {
      n: vds?.content?.data?.msg?.pi?.n,
      dob: vds?.content?.data?.msg?.pi?.dob,
      nl: vds?.content?.data?.msg?.pi?.nl, // optional
      pn: vds?.content?.data?.msg?.pi?.pn,
      sex: vds?.content?.data?.msg?.pi?.sex, // optional
    }

    const dta = {
      dtan: vds?.content?.data?.msg?.dtan,
      poi: vds?.content?.data?.msg?.dta?.poi,
      vf: vds?.content?.data?.msg?.dta?.vf,
      vu: vds?.content?.data?.msg?.dta?.vu,
      dos: vds?.content?.data?.msg?.dta?.dos, // optional
      noe: vds?.content?.data?.msg?.dta?.noe,
      tcc: vds?.content?.data?.msg?.dta?.tcc,
      ai: vds?.content?.data?.msg?.dta?.ai, // optional
    }

    return (
      <Grid container spacing={2}>
        <Item name value={pid.n} xs={12} />
        <Item label={i18n.t('VDSNCdisplay.DATE_OF_BIRTH')} value={pid.dob} xs={6} />
        <Item label={i18n.t('VDSNCdisplay.NATIONALITY')} value={pid.nl || '?'} xs={6} />
        <Item label={i18n.t('VDSNCdisplay.PASSPORT_NUMBER')} value={pid.pn} xs={6} />
        <Item label={i18n.t('VDSNCdisplay.SEX')} value={pid.sex || '?'} xs={6} />
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Item title value={i18n.t('VDSNCdisplay.DTA_TITLE')} xs={12} />
                <Item label={i18n.t('VDSNCdisplay.DTA_NUMBER')} value={dta.dtan} xs={6} />
                <Item label={i18n.t('VDSNCdisplay.PLACE_OF_ISSUE')} value={dta.poi} xs={6} />
                <Item label={i18n.t('VDSNCdisplay.VALID_FROM')} value={dta.vf} xs={6} />
                <Item label={i18n.t('VDSNCdisplay.VALID_UNTIL')} value={dta.vu} xs={6} />
                <Item label={i18n.t('VDSNCdisplay.NUMBER_OF_ENTRIES')} value={parseNumberOfEntries(dta.noe)} xs={6} />
                <Item label={i18n.t('VDSNCdisplay.VISA_TYPE')} value={dta.tcc} xs={6} />
                {dta.dos && <Item label={i18n.t('VDSNCdisplay.DURATION_OF_STAY')} value={parseDurationOfStay(dta.dos)} xs={12} />}
                {dta.ai && <Item label={i18n.t('VDSNCdisplay.ADDITIONAL_INFO')} value={dta.ai} xs={12} />}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
  //#endregion

  function parseDurationOfStay(dos) {
    // YYMMDD -> YY years, MM months, DD days

    if (!dos || dos.length !== 6) {
      return dos
    }

    const years = `${parseInt(dos.slice(0, 2))} ${i18n.t('VDSNCdisplay.DURATION_OF_STAY_YEARS')}`
    const months = `${parseInt(dos.slice(2, 4))} ${i18n.t('VDSNCdisplay.DURATION_OF_STAY_MONTHS')}`
    const days = `${parseInt(dos.slice(4, 6))} ${i18n.t('VDSNCdisplay.DURATION_OF_STAY_DAYS')}`

    return `${years}, ${months}, ${days}`
  }

  function parseNumberOfEntries(noe) {
    return noe === 'M'
      ? i18n.t('VDSNCdisplay.NUMBER_OF_ENTRIES_MULTIPLE')
      : noe
  }

  function switchVDS(vds) {
    switch (vds?.content?.data?.hdr?.t) {
      case 'icao.vacc':
        return <PoV vds={vds}/>
      case 'icao.rcvy':
        return <PoR vds={vds}/>
      case 'icao.test':
        return <PoT vds={vds}/>
      case 'icao.dta':
        return <DTA vds={vds}/>
      default:
        return <div></div>
    }
  }

  const [openInfo, setOpenInfo] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)

  React.useEffect(() => {
    setOpenInfo(false)
  }, [vds])

  return (

    <div style={styles.prettyContainer}>
      {vds.errors.length === 0
        ? <Grid container style={styles.successBox}>
            <Grid item style={styles.centerContent} xs={2}>
              <CheckCircle style={styles.bigIcon} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">{i18n.t('VDSNCdisplay.AUTHENTIC_TITLE')}</Typography>
              <Typography variant="body1">{i18n.t('VDSNCdisplay.AUTHENTIC_TEXT')}</Typography>
            </Grid>
          </Grid>
        : <Grid container style={styles.errorBox}>
            <Grid item style={styles.centerContent} xs={2}>
              <Error style={styles.bigIcon} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">{i18n.t('VDSNCdisplay.INVALID_TITLE')}</Typography>
              <Typography variant="body1">{i18n.t('VDSNCdisplay.INVALID_TEXT')}</Typography>
            </Grid>
            <Grid item xs={2} style={styles.centerContent}>
              <IconButton style={styles.smallButton} onClick={() => { setOpenError(!openError) }}>
                <InfoOutlined style={styles.smallIcon} />
              </IconButton>
            </Grid>
            <Collapse in={openError} timeout="auto" style={styles.collapse}>
              <Divider style={styles.infoDivider} />
              <Typography variant="body1">{i18n.t('VDSNCdisplay.ERROR_TEXT')}</Typography>
              <Grid container spacing={1} style={{ marginTop: "5px", marginBottom: "5px" }}>
                {vds.errors.map((error, index) => (
                  <>
                    <Grid item xs={2} key={`error-icon-${index}`} style={styles.centerContent}>
                      <Error style={styles.smallIcon} />
                    </Grid>
                    <Grid item xs={10} key={`error-text-${index}`}>
                      <Typography variant="body1">{error}</Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Collapse>
          </Grid>
      }
      {vds.verified
        ? <Grid container style={styles.successBox}>
            <Grid item style={styles.centerContent} xs={2}>
              <WorkspacePremium style={styles.mediumIcon} />
            </Grid>
            <Item title value={i18n.t('VDSNCdisplay.SIGNATURE_VERIFIED')} xs={8} />
            <Grid item xs={2} style={styles.centerContent}>
              <IconButton style={styles.smallButton} onClick={() => {setOpenInfo(!openInfo)}}>
                <InfoOutlined style={styles.smallIcon} />
              </IconButton>
            </Grid>
            <Collapse in={openInfo} timeout="auto" style={styles.collapse}>
              <Divider style={styles.infoDivider} />
              <Grid container spacing={1}>
                <Item label={i18n.t('VDSNCdisplay.ISSUING_COUNTRY')} value={vds.content.data.hdr.is} xs={12} />
                <Item label={i18n.t('VDSNCdisplay.SIGNER_CERTIFICATE_LABEL')} value={vds.signerCertificate?.cacheObject?.label} xs={6} />
                <Item label={i18n.t('VDSNCdisplay.CA_CERTIFICATE_LABEL')} value={vds.CACertificate?.cacheObject?.label} xs={6} />
                {vds.content?.sig?.cref && (
                  <Item label={i18n.t('VDSNCdisplay.CERTIICATE_REFERENCE')} value={vds.content.sig.cref} xs={12} />
                )}
              </Grid>
            </Collapse>
          </Grid>
        : <Grid container style={styles.errorBox}>
            <Grid item style={styles.centerContent} xs={2}>
              <WorkspacePremium style={styles.mediumIcon} />
            </Grid>
            <Item title value={i18n.t('VDSNCdisplay.SIGNATURE_NOT_VERIFIED')} xs={10} />
          </Grid>
      }
      { switchVDS(vds) }
    </div>
  )
}

function Item(props) {
  function Content(props) {
    if (props.name) {
      return <Typography variant="h6">{props.value.toUpperCase()}</Typography>
    }

    if (props.title) {
      return <Typography variant="h5">{props.value}</Typography>
    }
    
    return (
      <>
        <Typography
          variant={props.inline ? 'body1' : 'body2'}
          display={props.inline ? 'inline' : 'block'}
        >
          {`${props.label}: `}
        </Typography>
        {props.value !== undefined &&
          <Typography
            variant="body1"
            display={props.inline ? 'inline' : 'block'}
          >
            <Box fontWeight='bold' component="span">{props.value}</Box>
          </Typography>
        }
        {props.children &&
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: 5 }}
          >
            <Divider orientation="vertical" flexItem style={{ margin: '0 10px' }} />
            <Grid container spacing={1}>
              {props.children}
            </Grid>
          </Box>
        }
      </>
    )
  }

  return (
    <Grid item xs={props.xs}>
      <Content {...props} />
    </Grid>
  )
}

const styles = {
  prettyContainer: {
    padding: 20,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  treeItem: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  successBox: {
    borderRadius: 15,
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    alignItems: 'center',
    padding: 15,
    marginBottom: 20
  },
  errorBox: {
    borderRadius: 15,
    backgroundColor: theme.palette.red.main,
    color: theme.palette.white.main,
    alignItems: 'center',
    padding: 15,
    marginBottom: 20
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bigIcon: {
    fontSize: 50
  },
  mediumIcon: {
    fontSize: 30
  },
  smallIcon: {
    color: theme.palette.white.main,
    fontSize: 20
  },
  contactIcon: {
    fontSize: 15,
    marginRight: 10
  },
  collapse: {
    width: '100%'
  },
  infoDivider: {
    backgroundColor: theme.palette.white.main,
    marginTop: 15,
    marginBottom: 15
  },
  smallButton: {
    minWidth: 0,
    borderRadius: 50,
  },
  vdTitle: {
    marginTop: 10
  },
  vdDivider: {
    marginTop: 10,
    marginBottom: 10
  }
}