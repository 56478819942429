/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import BarCodeScanner from 'BarCodeScanner'
import Base from 'common/Base'
import DataDrawer, { drawerBleeding } from 'common/DataDrawer'

function Main(props) {
  const [barCode, setBarCode] = React.useState()
  const drawerRef = React.useRef()
  const scannerRef = React.useRef()

  const handleBarCodeSuccess = (scanResult) => {
    setBarCode(scanResult)
    drawerRef.current?.toggleDrawer(true)
  }

  return (
    <Base
      showMenu={true}
      menuButtonStyle={barCode && { marginBottom: `${drawerBleeding}px` }}
    >
      {barCode && (
        <DataDrawer
          ref={drawerRef}
          data={barCode}
          onOpen={() => scannerRef.current?.stop()}
          onClose={() => scannerRef.current?.start()}
        />
      )}

      <BarCodeScanner
        ref={scannerRef}
        onScanSuccess={handleBarCodeSuccess}
        style={styles.barCodeScanner}
      />
    </Base>
  )
}

const styles = {
  barCodeScanner: {
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default Main
