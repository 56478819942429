/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import Ajv from 'ajv'
import * as constants from 'constants'
import i18n from 'i18n-js'
import hash from 'hash.js'
import { VDSNC as VDSNC_schema, VDSNC_DTA_msg, VDSNC_PoR_msg, VDSNC_PoT_msg, VDSNC_PoV_msg_v1, VDSNC_PoV_msg_v2 } from "./JSONSchemas"
import Cache from 'common/Cache'
import Crypto from 'common/Crypto'
import Utils from 'common/Utils'
import { PrettyPage, RawPage } from 'VDSNCdisplay'

const ajv = new Ajv({strictTuples: false})

class VDSNC {
  constructor(input) {
    this.rawData = input
    this.content = {}
    this.errors = []
    this.signed = false
    this.verified = false

    // UUID is are used to anonymously identify the VDS-NC in the cache
    // Same scan of the same VDS-NC will have the same UUID
    this.UUID = hash.sha1().update(input).digest('hex')

    this.parse()
  }

  /**
   * Returns wheter the input is a VDS-NC.
   */
  static test(input) {
    try {
      const content = JSON.parse(input)
      const validate_VDSNC = ajv.compile(VDSNC_schema)
      return validate_VDSNC(content)
    } catch (error) {
      return false
    }
  }

  addError(error) {
    this.errors.push(error)
  }

  //#region Format validation
  hasValidDocumentType() {
    return /^(P|A|C|I|AC|V|D)$/.test(this.content?.data?.msg?.pid?.dt)
  }

  parsePoT() {
    const validate_PoT = ajv.compile(VDSNC_PoT_msg)
    const valid_PoT = validate_PoT(this.content.data.msg)

    if (!valid_PoT) {
      this.addError(i18n.t('VDSNC.POT_INVALID_FORMAT') + ':\n' + ajv.errorsText(validate_PoT.errors))
    }

    if (!this.content.sig) {
      this.addError(i18n.t('VDSNC.POT_NOT_SIGNED'))
    }

    if (!this.hasValidDocumentType()) {
      this.addError(i18n.t('VDSNC.POT_INVALID_DOCUMENT_TYPE'))
    }

    if (this.content.sig && !this.content.data.msg.utci) {
      this.addError(i18n.t('VDSNC.POT_MISSING_UTCI'))
    }
  }

  parsePoR() {
    const validate_PoR = ajv.compile(VDSNC_PoR_msg)
    const valid_PoR = validate_PoR(this.content.data.msg)

    if (!valid_PoR) {
      this.addError(i18n.t('VDSNC.POR_INVALID_FORMAT') + ':\n' + ajv.errorsText(validate_PoR.errors))
    }

    if (!this.content.sig) {
      this.addError(i18n.t('VDSNC.POR_NOT_SIGNED'))
    }

    if (!this.hasValidDocumentType()) {
      this.addError(i18n.t('VDSNC.POR_INVALID_DOCUMENT_TYPE'))
    }
  }

  parsePoV() {
    if (this.content.data.hdr.v === 1) {
      const validate_PoV_v1 = ajv.compile(VDSNC_PoV_msg_v1)
      const valid_PoV_v1 = validate_PoV_v1(this.content.data.msg)

      if (!valid_PoV_v1) {
        this.addError(i18n.t('VDSNC.POV_INVALID_FORMAT') + ':\n' + ajv.errorsText(validate_PoV_v1.errors))
      }
    } else if (this.content.data.hdr.v === 2) {
      const validate_PoV_v2 = ajv.compile(VDSNC_PoV_msg_v2)
      const valid_PoV_v2 = validate_PoV_v2(this.content.data.msg)

      if (!valid_PoV_v2) {
        this.addError(i18n.t('VDSNC.POV_INVALID_FORMAT') + ':\n' + ajv.errorsText(validate_PoV_v2.errors))
      }

      for (const ve of this.content.data.msg.ve) {
        if (!ve.mfg && !ve.mah) {
          this.addError(i18n.t('VDSNC.POV_MISSING_MFG_MAH'))
          break
        }
      }
    } else {
      this.addError(i18n.t('VDSNC.POV_INVALID_VERSION'))
      return // don't parse as PoV v2 if version is not
    }

    if (!this.content.sig) {
      this.addError(i18n.t('VDSNC.POV_NOT_SIGNED'))
    }

    if (!this.content.data.msg.pid.dob && !this.content.data.msg.pid.i) {
      this.addError(i18n.t('VDSNC.POV_MISSING_DOB_UID'))
    }
  }

  parseDTA() {
    const validate_DTA = ajv.compile(VDSNC_DTA_msg)
    const valid_DTA = validate_DTA(this.content.data.msg)

    if (!valid_DTA) {
      this.addError(i18n.t('VDSNC.DTA_INVALID_FORMAT') + ':\n' + ajv.errorsText(validate_DTA.errors))
    }

    if (!this.content.sig) {
      this.addError(i18n.t('VDSNC.DTA_NOT_SIGNED'))
    }
  }

  parse() {
    // Parse the content of the VDS-NC, determine its type and check for errors.

    try {
      this.content = JSON.parse(this.rawData)
    } catch (e) {
      this.addError(i18n.t('VDSNC.NOT_JSON'))
      return
    }

    const validate_VDSNC = ajv.compile(VDSNC_schema)
    const valid_VDSNC = validate_VDSNC(this.content)

    if (!valid_VDSNC) {
      this.addError(i18n.t('VDSNC.NOT_VDSNC') + ':\n' + ajv.errorsText(validate_VDSNC.errors))
      return
    }
    // This also means that if header is missing, the VDS-NC is invalid and is not parsed

    if (this.content.sig && this.content.sig.alg
      && this.content.sig.alg !== 'ES256'
      && this.content.sig.alg !== 'ES384'
      && this.content.sig.alg !== 'ES512') {
      this.errors.push(i18n.t('VDSNC.INVALID_SIGNATURE_ALGORITHM'))
    }

    // if .sig field is present, it has been checked by the VDS-NC JSON schema and is valid
    this.signed = this.content.sig !== undefined

    switch(this.content.data.hdr.t) {
      case 'icao.dta':
        this.parseDTA()
        break
      case 'icao.rcvy':
        this.parsePoR()
        break
      case 'icao.test':
        this.parsePoT()
        break
      case 'icao.vacc':
        this.parsePoV()
        break
      default:
        this.addError(i18n.t('VDSNC.UNKNOWN_TYPE'))
        return
    }
  }
  //#endregion

  //#region Signature verification
  /**
   * Find the certificate in the cache that matches the cref of the VDS-NC.
   * @param {Object[]} cachedCerts The certificates in the cache.
   * @returns {Object}             The matching certificate.
   */
  findCertificateInCache(cachedCerts) {
    const cref = this.content?.sig?.cref

    if (!cref) {
      return
    }

    return cachedCerts.find(cert => {
      try {
        const subjectCountryCode = cert.certificate.subject.typesAndValues
          .find(e => e.type === '2.5.4.6').value.valueBlock.value
        const subjectSerialNumberBytes = cert.certificate.serialNumber.valueBeforeDecodeView
        // 2 bytes for the tag and length
        const subjectSerialNumber = Utils.arrayBufferToHex(subjectSerialNumberBytes.slice(2))
        const cachedCertCref = subjectCountryCode + subjectSerialNumber
        return cachedCertCref.toUpperCase() === cref.toUpperCase()
      } catch (e) {
        return false
      }
    })
  }

  async verify() {
    if (!this.signed) {
      return
    }

    const cachedCerts = await Crypto.getCertificatesInCache()
    const cert = this.content.sig.cer || this.findCertificateInCache(cachedCerts)

    if (!cert) {
      this.addError(i18n.t('VDSNC.CERTIFICATE_NOT_FOUND'))  
      return
    }

    this.signerCertificate = cert

    try {
      const sigb64 = Utils.stripUrlEncoding(this.content.sig.sigvl)
      const signature = Crypto.splitECDSASignature(sigb64)

      const data = Utils.canonicalizeObject(this.content.data)
      const signatureAlgorithms = {
        ['ES256']: { name: 'ECDSA', hash: { name: 'SHA-256' } },
        ['ES384']: { name: 'ECDSA', hash: { name: 'SHA-384' } },
        ['ES512']: { name: 'ECDSA', hash: { name: 'SHA-512' } }
      }
      const signatureAlgorithm = signatureAlgorithms[this.content.sig.alg]
      const verified = await Crypto.verifySignatureWithCertificate(cert.certificate, signature, data, signatureAlgorithm)
    
      if (!verified) {
        this.addError(i18n.t('VDSNC.INVALID_SIGNATURE'))
        return
      }
    } catch (e) {
      this.addError(i18n.t('VDSNC.INVALID_SIGNATURE'))
      return
    }

    try {
      const signerCertCheckErrors = await Crypto.VDSNCsignerCertificateChecks(cert.certificate, this)
      signerCertCheckErrors.forEach(error =>
        this.addError(`${i18n.t('VDSNC.SIGNER_CERTIFICATE')}: ${error}`)
      )
    } catch (error) { }

    let issuerCert
    try {
      issuerCert = await Crypto.verifyCertAgainstCache(cert, cachedCerts)
    } catch (e) {
      this.addError(i18n.t('VDSNC.INVALID_CERTIFICATE'))
      return
    }

    if (!issuerCert) {
      this.addError(i18n.t('VDSNC.INVALID_CERTIFICATE'))
      return
    } else {
      this.verified = true
      this.CACertificate = issuerCert
    }
  }
  //#endregion
  
  //#region Display functions
  toString() {
    // truncate hash to 7 digits for simple authentication in logs
    return `VDS [${this.UUID.substring(0, 7)}]`
  }

  log() {
    Cache.log(
      constants.INFO,
      this.toString(),
      {
        uuid: this.UUID,
        errors: this.errors,
        signed: this.signed,
        verified: this.verified,
        signerCertificate: this.signerCertificate?.cacheObject?.label || i18n.t('VDSNC.NOT_APPLICABLE'),
        CACertificate: this.CACertificate?.cacheObject?.label || i18n.t('VDSNC.NOT_APPLICABLE'),
      }
    )
  }

  prettyDisplay() {
    return <PrettyPage vds={this} />
  }

  rawDisplay() {
    return <RawPage vds={this} />
  }

  get isPrettyDisplayable() {
    return /^icao\.(test|vacc|rcvy|dta)$/.test(this.content?.data?.hdr?.t)
  }
  //#endregion
}

export default VDSNC