/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import Cache from 'common/Cache'
import Utils from 'common/Utils'
import * as constants from '../constants'
import { dateFormat, dateTimeFormat } from 'theme'
import i18n from 'i18n-js'
import hash from 'hash.js'
import moment from 'moment'
import * as pkijs from 'pkijs'
import * as asn1js from 'asn1js'
import { ec as EC, curves } from 'elliptic'

/**
 * Converts a hash function string to a hash function.
 * @param {string} str The hash function string.
 * @returns {Function} The hash function.
 * @throws {Error}     If the hash function is unknown.
 */
function str2hash(str) {
  const hashFunctions = {
    'SHA-1': hash.sha1,
    'SHA-256': hash.sha256,
    'SHA-384': hash.sha384,
    'SHA-512': hash.sha512,
  }

  if (hashFunctions[str] === undefined) {
    throw new Error(i18n.t('Crypto.UNKNOWN_HASH_FUNCTION'))
  }

  return hashFunctions[str]
}

/**
 * Gets the certificates in the cache.
 * @returns {Promise<Object[]>} The certificates in the cache.
 */
async function getCertificatesInCache() { 
  const res = []
  const cachedCerts = await Cache.getAll(constants.CACHE_VALUES.tables.certificates.name)
    .catch(() => [])
  cachedCerts.forEach(cert => {
    try {
      const certArray = Utils.base64ToArrayBuffer(cert.certificate)
      const certObj = pkijs.Certificate.fromBER(certArray)
      res.push({ cacheObject: cert, certificate: certObj })
      //res.push(certObj)
    } catch (err) { }
  })

  return res
}

/**
 * Verifies a certificate against certificates in the cache.
 * @param {Object} cert                   The certificate to verify.
 * @param {Object[]} cachedCerts          The  certificates from the cache.
 * @returns {Promise<Object | undefined>} The verified certificate if successful, otherwise undefined.
 */
async function verifyCertAgainstCache(cert, cachedCerts) {
  for (const cachedCert of cachedCerts) {
    try {
      const verified = await verifyCertificate(
        cert.certificate,
        cachedCert.certificate
      )
      if (verified) {
        return cachedCert
      }
    } catch (err) { }
  }
}

/**
 * Gets the thumbprint of a certificate as a hex string.
 * @param {pkijs.Certificate} cert The certificate.
 * @param {string} hashFun         The hash function to use. E.g. 'SHA-256'.
 */
function getCertificateThumbprint(cert, hashFun) {
  let hashFunction
  try {
    hashFunction = str2hash(hashFun)
  } catch (err) {
    hashFunction = hash.sha256
  }
  
  return hashFunction().update(Utils.arrayBufferToHex(cert.toSchema().toBER())).digest('hex')
}

/**
 * Parses a certificate and returns a cache object.
 * Also makes the necessary checks for certificates import.
 * @param {string | pkijs.Certificate | Uint8Array | ArrayBuffer} input The certificate to parse.
 * @param {string} label                                                The label for the certificate.
 * @param {string} url                                                  The URL associated with the certificate.
 * @returns {Promise<Object>}                                           The certificate cache object.
 * @throws {Error}                                                      If the certificate cannot be parsed.
 */
async function toCacheObject(input, label) {
  const sizeThreshold = 1024 * 1024  // 1 MB

  if ((input instanceof ArrayBuffer || input instanceof Uint8Array)
    && input.byteLength > sizeThreshold) {
    throw new Error(i18n.t('Crypto.CERTIFICATE_SIZE_LIMIT'))
  }

  let cert = getCertificate(input)
  if (!cert && input instanceof ArrayBuffer) {
    // Try to parse the certificate as a string
    cert = getCertificate(Utils.arrayBufferToString(input), label)
  }

  if (!cert) {
    throw new Error(i18n.t('Crypto.CERTIFICATE_PARSE_ERROR'))
  }

  // Check validity of the certificate
  if (cert.notAfter?.value && moment(cert.notAfter.value).isBefore(moment())) {
    throw new Error(i18n.t('Crypto.CERTIFICATE_EXPIRED'))
  }

  if (cert.notBefore?.value && moment(cert.notBefore.value).isAfter(moment())) {
    throw new Error(i18n.t('Crypto.CERTIFICATE_NOT_YET_VALID'))
  }
  
  return {
    label: label,
    timestamp: moment().format(dateTimeFormat),
    subjectCommonName: cert.subject.typesAndValues
      .find(e => e.type === '2.5.4.3').value.valueBlock.value,
    issuerCommonName: cert.issuer.typesAndValues
      .find(e => e.type === '2.5.4.3').value.valueBlock.value,
    expirationDate: moment(cert.notAfter.value).format(dateFormat),
    thumbprint: getCertificateThumbprint(cert),
    certificate: cert.toString('base64'),
  }
}

/**
 * Converts a certificate to an ArrayBuffer.
 * @param {string | pkijs.Certificate | Uint8Array | ArrayBuffer} cert The certificate to convert.
 * @returns {ArrayBuffer}                                               The resulting ArrayBuffer.
 * @throws {Error}                                                      If the certificate as an unsupported format.
 */
function toCertificateBuffer(rawCert) {
  if (typeof rawCert === "string") {
    if (rawCert.startsWith("-----BEGIN CERTIFICATE-----")) { // PEM
      const lines = rawCert.split('\n').filter(line => !line.startsWith('-----'))
      const b64 = lines.join('')
      return Utils.base64ToArrayBuffer(b64)
    } else { // base64 / base64url
      return Utils.base64ToArrayBuffer(Utils.stripUrlEncoding(rawCert))
    }
  } else if (rawCert instanceof pkijs.Certificate) {
    return rawCert.toSchema().toBER()
  } else if (rawCert instanceof Uint8Array) {
    return Utils.Uint8ArrayToArrayBuffer(rawCert)
  } else if (rawCert instanceof ArrayBuffer) {
    return rawCert
  } else {
    throw new Error(i18n.t('Crypto.UNKNOWN_CERTIFICATE_FORMAT'))
  }
}

/**
 * Parses a certificate from a given input.
 * @param {string | pkijs.Certificate | Uint8Array | ArrayBuffer} input The certificate to parse.
 * @returns {pkijs.Certificate | null}                                  The parsed certificate, or null if parsing failed.
 * @throws {Error}                                                      If the certificate cannot be parsed.
 */
function getCertificate(input) {
  const certBuffer = toCertificateBuffer(input)
  const asn1 = asn1js.fromBER(certBuffer)

  if (asn1.offset === -1) {
    return null
  }

  return new pkijs.Certificate({ schema: asn1.result })
}

/**
 * Splits an ECDSA signature into r and s components.
 * @param {string} sigb64 The base64 encoded signature.
 * @returns {Object}      The signature components with r and s as hex strings.
 * @throws {Error}        If the signature cannot be split.
 */
function splitECDSASignature(sigb64) {
  const sigBuffer = new Uint8Array(Utils.base64ToArrayBuffer(sigb64))
  // sig = r | s
  return {
    r: Utils.arrayBufferToHex(sigBuffer.slice(0, sigBuffer.length / 2)),
    s: Utils.arrayBufferToHex(sigBuffer.slice(sigBuffer.length / 2))
  }
}

/**
 * Parses the EC parameters from the ASN.1 structure bytes.
 * @param {Uint8Array} asn1Data The ASN.1 structure bytes.
 * @returns {Object}            The parsed EC parameters.
 * @throws {Error}              If the EC parameters cannot be parsed.
 */
function parseECParameters(asn1Data) {
  const asn1 = asn1js.fromBER(asn1Data)

  if (asn1.offset === -1) {
    throw new Error(i18n.t('Crypto.ASN1_PARSING_ERROR'))
  }

  const ecParams = asn1.result

  if (ecParams instanceof asn1js.ObjectIdentifier) {
    const oid = ecParams.valueBlock.toString()
    if (knownCurves[oid]) {
      return knownCurves[oid]
    } else {
      throw new Error(i18n.t('Crypto.UNKNOWN_ECDSA_CURVE'))
    }
  } else if (ecParams instanceof asn1js.Sequence) {
    const version = ecParams.valueBlock.value[0].valueBlock.valueDec  // Version (1)

    // FieldID (p and field type)
    const fieldID = ecParams.valueBlock.value[1]
    const p = fieldID.valueBlock.value[1].valueBlock.valueHex

    // Curve (a and b)
    const curve = ecParams.valueBlock.value[2]
    const a = curve.valueBlock.value[0].valueBlock.valueHex
    const b = curve.valueBlock.value[1].valueBlock.valueHex

    // Base Point (G)
    const basePointG = ecParams.valueBlock.value[3].valueBlock.valueHex

    // Order (n)
    const orderN = ecParams.valueBlock.value[4].valueBlock.valueHex

    // Optional Cofactor (h)
    const cofactorH = ecParams.valueBlock.value[5] ?
      ecParams.valueBlock.value[5].valueBlock.valueHex :
      '01'  // Optional cofactor (default 1)

    // Convert the base point G into separate x and y coordinates
    const gHex = Array.from(new Uint8Array(basePointG))

    // check if the base point is in uncompressed format (0x04 prefix)
    if (gHex[0] !== 0x04) {
      throw new Error(i18n.t('Crypto.COMPRESSED_POINT_NOT_SUPPORTED'))
    }

    const gxBytes = gHex.slice(1, gHex.length / 2 + 1)
    const gyBytes = gHex.slice(gHex.length / 2 + 1)
    const gx = Utils.arrayBufferToHex(gxBytes, true)
    const gy = Utils.arrayBufferToHex(gyBytes, true)

    // Determine the size of the base point generator G
    const gSizeInBits = gxBytes.length * 8

    let hashFun
    if (gSizeInBits <= 224) {
      hashFun = hash.sha224
    } else if (gSizeInBits <= 256) {
      hashFun = hash.sha256
    } else if (gSizeInBits <= 384) {
      hashFun = hash.sha384
    } else {
      hashFun = hash.sha512
    }

    return {
      type: 'short',
      p: Utils.arrayBufferToHex(p, true),
      a: Utils.arrayBufferToHex(a, true),
      b: Utils.arrayBufferToHex(b, true),
      n: Utils.arrayBufferToHex(orderN, true),
      gRed: false,  // No precomputed base point
      g: [gx, gy],  // Base point coordinates
      hash: hashFun
    }
  } else {
    throw new Error(i18n.t('Crypto.UNKNOWN_EC_PARAMETERS_FORMAT'))
  }
}

/**
 * Parses the public key information from given certificate.
 * @param {pkijs.Certificate} cert The certificate.
 * @returns {Object}               The parsed public key information.
 * @throws {Error}                 If the public key information cannot be parsed.
 */
function parseECPublicKeyInfo(cert) {
  const publicKeyInfo = cert.subjectPublicKeyInfo
  const algorithm = publicKeyInfo.algorithm
  const paramsBytes = algorithm.algorithmParams.valueBeforeDecode
  const curve = parseECParameters(paramsBytes)
  
  const publicKey = publicKeyInfo.subjectPublicKey.valueBlock.valueHexView
  const publicKeyHex = Array.from(new Uint8Array(publicKey))
  const x = Utils.arrayBufferToHex(publicKeyHex.slice(1, publicKeyHex.length / 2 + 1), true)
  const y = Utils.arrayBufferToHex(publicKeyHex.slice(publicKeyHex.length / 2 + 1), true)

  return {
    curve: curve,
    x: x,
    y: y
  }
}

/**
 * Verifies a signature with signer certificate.
 * @param {pkijs.Certificate} input   The certificate to use.
 * @param {Uint8Array} signature      The signature to verify.
 * @param {Uint8Array} data           The data to verify.
 * @param {Object} signatureAlgorithm The signature algorithm (optional).
 * @returns {Promise<boolean>}        True if the signature is valid.
 * @throws {Error}                    If any error occurs.
 */
async function verifySignatureWithCertificate(cert, signature, data, signatureAlgorithm) {
  const publicKeyAlgorithmOID = cert?.subjectPublicKeyInfo?.algorithm?.algorithmId

  if (!publicKeyAlgorithmOID) {
    throw new Error(i18n.t('Crypto.UNKNOWN_SIGNATURE_ALGORITHM'))
  }

  // ecPublicKey, can be explicit or named curve
  if (publicKeyAlgorithmOID === "1.2.840.10045.2.1") {
    if (signatureAlgorithm && signatureAlgorithm.name !== "ECDSA") {
      throw new Error(i18n.t('Crypto.INVALID_SIGNATURE_ALGORITHM'))
    }

    let publicKeyInfo
    try {
      publicKeyInfo = parseECPublicKeyInfo(cert)
    } catch (err) {
      throw new Error(i18n.t('Crypto.INVALID_EC_PUBLIC_KEY'))
    }

    if (signatureAlgorithm?.hash?.name) {
      // Override the hash function if specified
      publicKeyInfo.curve.hash = str2hash(signatureAlgorithm.hash.name)
    }
    const ec = new EC(new curves.PresetCurve(publicKeyInfo.curve))
    const hashedData = publicKeyInfo.curve.hash().update(data).digest()
    const pubKey = ec.keyFromPublic({
      x: publicKeyInfo.x,
      y: publicKeyInfo.y
    }, "hex")
    
    return pubKey.verify(hashedData, signature)
  } else {
    // RSA-PSS or RSAES-PKCS1-v1_5
    // FIXME: Is is possible to deduce the signature parameters?
    throw new Error(i18n.t('Crypto.UNKNOWN_SIGNATURE_ALGORITHM'))
  }
}

/**
 * Verifies wether the certificate is signed by the issuer certificate.
 * @param {string | pkijs.Certificate | Uint8Array | ArrayBuffer} rawCert       The certificate to verify.
 * @param {string | pkijs.Certificate | Uint8Array | ArrayBuffer} rawIssuerCert The issuer certificate.
 * @returns {Promise<boolean>}                                                  True if the certificate is signed by the issuer certificate.
 * @throws {Error}                                                              If any error occurs.
 */
async function verifyCertificate(rawCert, rawIssuerCert) {
  const cert = getCertificate(rawCert)
  const issuerCert = getCertificate(rawIssuerCert)

  // check if the issuer certificate is the issuer of the certificate
  const issuerSubject = new Uint8Array(issuerCert.subject.toSchema().toBER())
  const certIssuer = new Uint8Array(cert.issuer.toSchema().toBER())
  
  const isIssuer = issuerSubject.length === certIssuer.length
    && issuerSubject.every((value, index) => value === certIssuer[index])

  if (!isIssuer) {
    throw new Error(i18n.t('Crypto.INVALID_ISSUER_CERTIFICATE'))
  }

  const issuerPubKeyAlgOID = cert?.subjectPublicKeyInfo?.algorithm?.algorithmId
  const signatureAlgOID = cert?.signatureAlgorithm?.algorithmId

  const signatureAlgorithms = {
    "1.2.840.10045.4.3.1": { name: 'ECDSA', hash: { name: 'SHA-224' } },
    "1.2.840.10045.4.3.2": { name: 'ECDSA', hash: { name: 'SHA-256' } },
    "1.2.840.10045.4.3.3": { name: 'ECDSA', hash: { name: 'SHA-384' } },
    "1.2.840.10045.4.3.4": { name: 'ECDSA', hash: { name: 'SHA-512' } },
    "1.2.840.113549.1.1.5": { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-1" } },
    "1.2.840.113549.1.1.11": { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-256" } },
    "1.2.840.113549.1.1.12": { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-384" } },
    "1.2.840.113549.1.1.13": { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-512" } },
    "1.2.840.113549.1.1.10": { name: "RSA-PSS", hash: { name: "SHA-256" } } // Default to SHA-256 ?
  }

  if (!signatureAlgOID
    || !issuerPubKeyAlgOID
    || !signatureAlgorithms[signatureAlgOID]) {
    throw new Error(i18n.t('Crypto.UNKNOWN_SIGNATURE_ALGORITHM'))
  }

  if (signatureAlgorithms[signatureAlgOID].name === "ECDSA") {
    return await verifySignatureWithCertificate(
      issuerCert,
      cert.signatureValue.valueBlock.valueHexView,
      cert.tbsView,
      signatureAlgorithms[cert.signatureAlgorithm.algorithmId]
    )
  } else {
    return await cert.verify(issuerCert)
  }
}

async function VDSNCsignerCertificateChecks(cert, vdsnc) {
  let errors = []

  // (1) check that certificate is ECC with named curve
  if (cert.subjectPublicKeyInfo?.algorithm?.algorithmId !== "1.2.840.10045.2.1") {
    errors.push(i18n.t('Crypto.INVALID_PUBLIC_KEY_ALGORITHM'))
  } else {
    const ecParams = cert.subjectPublicKeyInfo?.algorithm?.algorithmParams

    if (ecParams instanceof asn1js.ObjectIdentifier) {
      const oid = ecParams.valueBlock.toString()
      if (!knownCurves[oid]) {
        errors.push(i18n.t('Crypto.UNKNOWN_ECDSA_CURVE'))
      }
    } else {
      errors.push(i18n.t('Crypto.INVALID_EC_PARAMETERS_FORMAT'))
    }
  }

  // (2) The EKU OID for VDS-NC Signers is “2.23.136.1.1.14.2”
  const EKU = cert.extensions?.find(
    extension => extension.extnID === "2.5.29.37")

  if (!EKU || EKU.parsedValue?.keyPurposes?.[0] !== "2.23.136.1.1.14.2") {
    errors.push(i18n.t('Crypto.INVALID_EXTENDED_KEY_USAGE'))
  }

  // (3) DocumentType extension
  const docType = cert.extensions?.find(
    extension => extension.extnID === "2.23.136.1.1.6.2")

  const docTypeSchema = new asn1js.Sequence({
    value: [
      new asn1js.Integer(),
      new asn1js.Set({
        value: [
          new asn1js.Repeated({ value: new asn1js.PrintableString() })
        ]
      })
    ]
  })

  const docTypeVerified = asn1js.verifySchema(
    docType.parsedValue.toBER(),
    docTypeSchema
  )

  if (!docType || !docTypeVerified.verified) {
    errors.push(i18n.t('Crypto.INVALID_DOCUMENT_TYPE_EXTENSION'))
  }

  const version = docTypeVerified.result.valueBlock.value[0]
  const set = docTypeVerified.result.valueBlock.value[1]

  // check documentType version is 0 (ICAO 9303-12: 7.1.1.6)
  if (version.valueBlock.valueDec !== 0) {
    errors.push(i18n.t('Crypto.INVALID_DOCUMENT_TYPE_VERSION'))
  }

  // check that the set contains only printable strings
  if (!set.valueBlock.value.every(e => e instanceof asn1js.PrintableString)) {
    errors.push(i18n.t('Crypto.INVALID_DOCUMENT_TYPE_EXTENSION'))
  }

  // check that the certificate can sign the document type relative to the
  // VDS-NC type.
  const docTypes = {
    "icao.test": "NT",
    "icao.vacc": "NV",
    "icao.rcvy": "NR",
    "icao.dta": "ND"
  }
  const expectedDocType = docTypes[vdsnc.content?.data?.hdr?.t]

  // if certificate's can sign document type "N", then it can sign documents
  // of type "N*"
  if (!set.valueBlock.value
    .some(docType => expectedDocType.startsWith(docType.valueBlock.value))
  ) {
    errors.push(i18n.t('Crypto.MISSING_DOCUMENT_TYPE').replace(/%s/, expectedDocType))
  }

  // (4) AuthorityKeyIdentifier extension
  const authorityKeyIdentifier = cert.extensions?.find(
    extension => extension.extnID === "2.5.29.35")

  if (!authorityKeyIdentifier?.parsedValue?.keyIdentifier) {
    errors.push(i18n.t('Crypto.INVALID_AUTHORITY_KEY_IDENTIFIER'))
  }

  return errors
}

const knownCurves = {
  "1.2.840.10045.3.1.7": { // prime256v1 / secp256r1
    type: 'short',
    prime: null,
    p: 'ffffffff 00000001 00000000 00000000 00000000 ffffffff ffffffff ffffffff',
    a: 'ffffffff 00000001 00000000 00000000 00000000 ffffffff ffffffff fffffffc',
    b: '5ac635d8 aa3a93e7 b3ebbd55 769886bc 651d06b0 cc53b0f6 3bce3c3e 27d2604b',
    n: 'ffffffff 00000000 ffffffff ffffffff bce6faad a7179e84 f3b9cac2 fc632551',
    hash: hash.sha256,
    gRed: false,
    g: [
      '6b17d1f2 e12c4247 f8bce6e5 63a440f2 77037d81 2deb33a0 f4a13945 d898c296',
      '4fe342e2 fe1a7f9b 8ee7eb4a 7c0f9e16 2bce3357 6b315ece cbb64068 37bf51f5',
    ],
  },
  "1.3.132.0.34": { // prime384v1 / secp384r1
    type: 'short',
    prime: null,
    p: 'ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'fffffffe ffffffff 00000000 00000000 ffffffff',
    a: 'ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'fffffffe ffffffff 00000000 00000000 fffffffc',
    b: 'b3312fa7 e23ee7e4 988e056b e3f82d19 181d9c6e fe814112 0314088f ' +
       '5013875a c656398d 8a2ed19d 2a85c8ed d3ec2aef',
    n: 'ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff c7634d81 ' +
       'f4372ddf 581a0db2 48b0a77a ecec196a ccc52973',
    hash: hash.sha384,
    gRed: false,
    g: [
      'aa87ca22 be8b0537 8eb1c71e f320ad74 6e1d3b62 8ba79b98 59f741e0 82542a38 ' +
      '5502f25d bf55296c 3a545e38 72760ab7',
      '3617de4a 96262c6f 5d9e98bf 9292dc29 f8f41dbd 289a147c e9da3113 b5f0b8c0 ' +
      '0a60b1ce 1d7e819d 7a431d7c 90ea0e5f',
    ],
  },
  "1.3.132.0.35": { // ansip521r1 / secp521r1
    type: 'short',
    prime: null,
    p: '000001ff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'ffffffff ffffffff ffffffff ffffffff ffffffff',
    a: '000001ff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'ffffffff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'ffffffff ffffffff ffffffff ffffffff fffffffc',
    b: '00000051 953eb961 8e1c9a1f 929a21a0 b68540ee a2da725b ' +
       '99b315f3 b8b48991 8ef109e1 56193951 ec7e937b 1652c0bd ' +
       '3bb1bf07 3573df88 3d2c34f1 ef451fd4 6b503f00',
    n: '000001ff ffffffff ffffffff ffffffff ffffffff ffffffff ' +
       'ffffffff ffffffff fffffffa 51868783 bf2f966b 7fcc0148 ' +
       'f709a5d0 3bb5c9b8 899c47ae bb6fb71e 91386409',
    hash: hash.sha512,
    gRed: false,
    g: [
      '000000c6 858e06b7 0404e9cd 9e3ecb66 2395b442 9c648139 ' +
      '053fb521 f828af60 6b4d3dba a14b5e77 efe75928 fe1dc127 ' +
      'a2ffa8de 3348b3c1 856a429b f97e7e31 c2e5bd66',
      '00000118 39296a78 9a3bc004 5c8a5fb4 2c7d1bd9 98f54449 ' +
      '579b4468 17afbd17 273e662c 97ee7299 5ef42640 c550b901 ' +
      '3fad0761 353c7086 a272c240 88be9476 9fd16650',
    ],
  },
  "1.3.36.3.3.2.8.1.1.7": { // brainpoolP256r1
    type: 'short',
    prime: null,
    p: 'a9fb57dba1eea9bc3e660a909d838d726e3bf623d52620282013481d1f6e5377',
    a: '7d5a0975fc2c3057eef67530417affe7fb8055c126dc5c6ce94a4b44f330b5d9',
    b: '26dc5c6ce94a4b44f330b5d9bbd77cbf958416295cf7e1ce6bccdc18ff8c07b6',
    n: 'a9fb57dba1eea9bc3e660a909d838d718c397aa3b561a6f7901e0e82974856a7',
    hash: hash.sha256,
    gRed: false,
    g: [
      '8bd2aeb9cb7e57cb2c4b482ffc81b7afb9de27e1e3bd23c23a4453bd9ace3262',
      '547ef835c3dac4fd97f8461a14611dc9c27745132ded8e545c1d54c72f046997',
    ],
  },
  "1.3.36.3.3.2.8.1.1.9": { // brainpoolP320r1
    type: 'short',
    prime: null,
    p: 'd35e472036bc4fb7e13c785ed201e065f98fcfa6f6f40def4f92b9ec7893ec28fcd412b1f1b32e27',
    a: '3ee30b568fbab0f883ccebd46d3f3bb8a2a73513f5eb79da66190eb085ffa9f492f375a97d860eb4',
    b: '520883949dfdbc42d3ad198640688a6fe13f41349554b49acc31dccd884539816f5eb4ac8fb1f1a6',
    n: 'd35e472036bc4fb7e13c785ed201e065f98fcfa5b68f12a32d482ec7ee8658e98691555b44c59311',
    hash: hash.sha384,
    gRed: false,
    g: [
      '43bd7e9afb53d8b85289bcc48ee5bfe6f20137d10a087eb6e7871e2a10a599c710af8d0d39e20611',
      '14fdd05545ec1cc8ab4093247f77275e0743ffed117182eaa9c77877aaac6ac7d35245d1692e8ee1',
    ],
  },
  "1.3.36.3.3.2.8.1.1.11": { // brainpoolP384r1
    type: 'short',
    prime: null,
    p: '8cb91e82a3386d280f5d6f7e50e641df152f7109ed5456b412b1da197fb71123acd3a729901d1a71874700133107ec53',
    a: '7bc382c63d8c150c3c72080ace05afa0c2bea28e4fb22787139165efba91f90f8aa5814a503ad4eb04a8c7dd22ce2826',
    b: '4a8c7dd22ce28268b39b55416f0447c2fb77de107dcd2a62e880ea53eeb62d57cb4390295dbc9943ab78696fa504c11',
    n: '8cb91e82a3386d280f5d6f7e50e641df152f7109ed5456b31f166e6cac0425a7cf3ab6af6b7fc3103b883202e9046565',
    hash: hash.sha512,
    gRed: false,
    g: [
      '1d1c64f068cf45ffa2a63a81b7c13f6b8847a3e77ef14fe3db7fcafe0cbd10e8e826e03436d646aaef87b2e247d4af1e',
      '8abe1d7520f9c2a45cb1eb8e95cfd55262b70b29feec5864e19c054ff99129280e4646217791811142820341263c5315',
    ],
  },
  "1.3.36.3.3.2.8.1.1.13": { // brainpoolP512r1
    type: 'short',
    prime: null,
    p: 'aadd9db8dbe9c48b3fd4e6ae33c9fc07cb308db3b3c9d20ed6639cca703308717d4d9b009bc66842aecda12ae6a380e62881ff2f2d82c68528aa6056583a48f3',
    a: '7830a3318b603b89e2327145ac234cc594cbdd8d3df91610a83441caea9863bc2ded5d5aa8253aa10a2ef1c98b9ac8b57f1117a72bf2c7b9e7c1ac4d77fc94ca',
    b: '3df91610a83441caea9863bc2ded5d5aa8253aa10a2ef1c98b9ac8b57f1117a72bf2c7b9e7c1ac4d77fc94cadc083e67984050b75ebae5dd2809bd638016f723',
    n: 'aadd9db8dbe9c48b3fd4e6ae33c9fc07cb308db3b3c9d20ed6639cca70330870553e5c414ca92619418661197fac10471db1d381085ddaddb58796829ca90069',
    hash: hash.sha512,
    gRed: false,
    g: [
      '81aee4bdd82ed9645a21322e9c4c6a9385ed9f70b5d916c1b43b62eef4d0098eff3b1f78e2d0d48d50d1687b93b97d5f7c6d5047406a5e688b352209bcb9f822',
      '7dde385d566332ecc0eabfa9cf7822fdf209f70024a57b1aa000c55b881f8111b2dcde494a5f485e5bca4bd88a2763aed1ca2b2fa8f0540678cd1e0f3ad80892',
    ],
  },
}

const Crypto = {
  splitECDSASignature,
  getCertificatesInCache,
  verifyCertAgainstCache,
  toCertificateBuffer,
  toCacheObject,
  verifySignatureWithCertificate,
  verifyCertificate,
  getCertificateThumbprint,
  VDSNCsignerCertificateChecks
}

export default Crypto