/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import moment from 'moment'
import i18n from 'i18n-js'
import hash from 'hash.js'
import Utils from 'common/Utils'
import Crypto from 'common/Crypto'
import Cache from 'common/Cache'
import * as constants from 'constants'
import { VDSP13 } from 'constants'
import {
  parseDocumentFeatures,
  decodeString,
  decodeDate
} from 'VDSp13decode'
import { PrettyPage, RawPage } from 'VDSp13display'

export class VDSError extends Error {
  constructor(message, subIndication, status) {
    super(message)
    this.name = 'VDSError'
    this.subIndication = subIndication
    if (status !== undefined) {
      this.status = status
    }
  }
}

class VDSp13 {
  /**
   * Create a new VDSp13 object.
   * @param {ArrayBuffer} input The VDS data as an ArrayBuffer.
   */
  constructor(input) {
    // see Appendix D to Part 13
    this.status = VDSP13.STATUS.VALID
    this.hasKnownDocumentFeatures = false

    // UUID is used to anonymously identify the VDS in the cache
    // Same scan of the same VDS will have the same UUID
    this.UUID = hash.sha1().update(input).digest('hex')

    this.dataView = new DataView(input)
    this.offset = 0x00
    this.raw = new Uint8Array(input)

    this.parse()
  }

  /**
   * Returns whether the input is a VDSp13 or not.
   * Currently, just checks the presence of the magic constant.
   */
  static test(input) {
    try {
      const dataView = new DataView(input)
      const magicConstant = dataView.getUint8(0x00)
      return magicConstant === 0xDC
    } catch (error) {
      return false
    }
  }

  handleError(error) {
    if (error instanceof VDSError) {
      if (error.status !== undefined) {
        this.status = error.status
      }
      this.subIndication = error.subIndication
    } else { // uncaught error
      this.status = VDSP13.STATUS.INVALID
      this.subIndication = VDSP13.SUB_INDICATION.WRONG_FORMAT
    }
  }

  //#region Read functions

  /**
   * Read a single byte from the data buffer. If offset is provided, read from
   * that offset and don't update this.offset.
   * 
   * @param {number} offset The offset to read from (optional).
   * @returns {number}      The byte read from the buffer (0-255).
   */
  readByte(offset) {
    const _offset = offset === undefined ? this.offset : offset
    if (_offset + 0x01 > this.dataView.byteLength) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_READ'),
        VDSP13.SUB_INDICATION.READ_ERROR,
        VDSP13.STATUS.INVALID
      )
    }
    const value = this.dataView.getUint8(_offset)
    if (offset === undefined) {
      this.offset += 0x01
    }
    return value
  }

  /**
   * Read multiple bytes from the data buffer. If offset is provided, read from
   * that offset and don't update this.offset.
   * 
   * @param {number} length The number of bytes to read.
   * @param {number} offset The offset to read from (optional).
   * @returns {Uint8Array}  The bytes read from the buffer (0-255).
   */
  readBytes(length, offset) {
    const _offset = offset === undefined ? this.offset : offset
    if (_offset + length > this.dataView.byteLength) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_READ'),
        VDSP13.SUB_INDICATION.READ_ERROR,
        VDSP13.STATUS.INVALID
      )
    }
    const bytes = new Uint8Array(this.dataView.buffer, _offset, length)
    if (offset === undefined) {
      this.offset += length
    }
    return bytes
  }

  //#endregion

  //#region Parsing and format validation

  /**
   * Parse and validate the format of the VDS as described in appendix D to the ICAO Doc
   * 9303 Part 13.
   */
  parse() {
    try {
      this.header = {}
      this.parseHeader()
      this.message = { rawDocumentFeatures: {} }
      this.header.version >= 0x03 ? this.parseDERTLV() : this.parseTLV()
      parseDocumentFeatures(this)

      if (this.status === VDSP13.STATUS.VALID) {
        this.hasKnownDocumentFeatures = true
      }

      this.signature = {}
      this.parseSignature()
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Parse the header of the VDS as described in section 2.2 of the ICAO Doc
   * 9303 Part 13.
   */
  parseHeader() {
    this.header.magicConstant = this.readByte()
    if (this.header.magicConstant !== 0xDC) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_MAGIC_CONSTANT'),
        VDSP13.SUB_INDICATION.READ_ERROR,
        VDSP13.STATUS.INVALID
      )
    }
    this.header.version = this.readByte()// TODO: handle version != from 0x02 or 0x03 ?
    this.header.issuingCountry = decodeString(this.readBytes(2))

    if (this.header.version >= 0x03) {
      // 4 bytes stores first 6 characters of Signer & Certificate Reference
      const signerIDandCertRefLength = decodeString(this.readBytes(4))
      this.header.signerIdentifier = signerIDandCertRefLength.slice(0, 4) // first 4 characters
      const certRefLength = parseInt(signerIDandCertRefLength.slice(4, 6), 16) // last 2 characters
      const bytesToRead = Math.ceil(certRefLength / 3) * 2 // C40 encodes 3 characters per 2 bytes
      this.header.certificateReference = decodeString(this.readBytes(bytesToRead))
    } else {
      const signerIdAndCertRef = decodeString(this.readBytes(6)) // 6 C40 bytes => 9 characters
      this.header.signerIdentifier = signerIdAndCertRef.slice(0, 4) // first 4 characters
      this.header.certificateReference = signerIdAndCertRef.slice(4) // last 5 characters
    }

    this.header.documentIssueDate = decodeDate(this.readBytes(3))
    this.header.signatureCreationDate = decodeDate(this.readBytes(3))
    this.header.documentFeatureDefinitionReference = this.readByte()
  
    if (this.header.documentFeatureDefinitionReference < 0x01
     || this.header.documentFeatureDefinitionReference > 0xFE) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_DOCUMENT_FEATURE_DEFINITION_REFERENCE'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }

    this.header.documentTypeCategory = this.readByte()

    // Values MUST be in the range between 01dec and 254dec
    if (this.header.documentTypeCategory < 0x01
     || this.header.documentTypeCategory > 0xFD) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_DOCUMENT_TYPE_CATEGORY'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }

    this.header.raw = this.readBytes(this.offset, 0x00) // from 0x00 to this.offset
  }

  /**
   * Parse the message zone for version number 3 as described in section 2.3 of
   * the ICAO Doc 9303 Part 13.
   */
  parseTLV() {
    const startOffset = this.offset
    while (this.offset < this.dataView.byteLength) {
      const tag = this.readByte()
      if (tag === 0xFF) {
        this.offset -= 0x01
        break
      }
      let length = this.readByte()
      const value = this.readBytes(length)
      this.message.rawDocumentFeatures[tag] = { length, value }
    }
    this.message.raw = this.readBytes(this.offset - startOffset, startOffset)
  }

  /**
   * Parse the message zone for version number 4 as described in section 2.3 of
   * the ICAO Doc 9303 Part 13.
   */
  parseDERTLV() {
    const startOffset = this.offset
    while (this.offset < this.dataView.byteLength) {
      const tag = this.readByte()
      if (tag === 0xFF) {
        this.offset -= 0x01
        break
      }

      let length = this.getDERTLVLength()
      let value = this.readBytes(length)
      this.message.rawDocumentFeatures[tag] = { length, value }
    }
    this.message.raw = this.readBytes(this.offset - startOffset, startOffset)
  }

  /**
   * Parse the signature of the VDS as described in section 2.4 and appendix D
   * to the ICAO Doc 9303 Part 13.
   */
  parseSignature() {
    const startOffset = this.offset
    const signatureMarker = this.readByte()

    if (signatureMarker !== 0xFF) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_SIGNATURE_MARKER'),
        VDSP13.SUB_INDICATION.WRONG_FORMAT,
        VDSP13.STATUS.INVALID
      )
    }

    this.signature.length = this.getDERTLVLength()
    this.signature.signatureData = this.readBytes(this.signature.length)
    this.signature.raw = this.readBytes(this.offset - startOffset, startOffset)
  }

  // Get the length of a DER TLV field.
  getDERTLVLength() {
    const length = this.readByte()
    if (length > 0x7F) {
      const lengthOfLength = length & 0x7F
      if (lengthOfLength > 0x04) {
        throw new VDSError(
          i18n.t('VDSp13.INVALID_TLV_LENGTH'),
          VDSP13.SUB_INDICATION.WRONG_FORMAT,
          VDSP13.STATUS.INVALID
        )
      }
      let length = 0
      for (let i = 0; i < lengthOfLength; i++) {
        length = (length << 8) | this.readByte()
      }
    }
    return length
  }
  //#endregion

  //#region Signature verification
  
  /**
   * Verify the signature of the VDS as described in section 2.5 of the ICAO
   * Doc 9303 Part 13.
   */
  async verify() {
    if (this.status === VDSP13.STATUS.INVALID) {
      return
    }

    try {
      await this._verify()
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Find the certificate in the cache that matches the signer identifier and
   * certificate reference of the VDS.
   * @param {Object[]} cachedCerts The certificates in the cache.
   * @returns {Object}             The matching certificate.
   */
  findCertificateInCache(cachedCerts) {
    const signerId = this.header.signerIdentifier
    const cref = this.header.certificateReference

    return cachedCerts.find(cert => {
      const subjectCountryCode = cert.certificate.subject.typesAndValues
        .find(e => e.type === '2.5.4.6').value.valueBlock.value
      const subjectCommonName = cert.certificate.subject.typesAndValues
        .find(e => e.type === '2.5.4.3').value.valueBlock.value
      const subjectSerialNumberBytes = cert.certificate.serialNumber.valueBeforeDecodeView
      // 2 bytes for the tag and length
      const subjectSerialNumber = Utils.arrayBufferToHex(subjectSerialNumberBytes.slice(2))
      
      return signerId === subjectCountryCode + subjectCommonName
          && subjectSerialNumber === cref
    })
  }

  async _verify() {
    const cachedCertificates = await Crypto.getCertificatesInCache()
    const signerCertificate  = this.findCertificateInCache(cachedCertificates)

    if (!signerCertificate) {
      throw new VDSError(
        i18n.t('VDSp13.SIGNER_CERTIFICATE_NOT_FOUND'),
        VDSP13.SUB_INDICATION.UNKNOWN_CERTIFICATE,
        VDSP13.STATUS.INVALID
      )
    }

    this.signerCertificate = signerCertificate

    let CACertificate
    try {
      CACertificate = await Crypto.verifyCertAgainstCache(signerCertificate, cachedCertificates)
    } catch (error) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_CERTIFICATE'),
        VDSP13.SUB_INDICATION.UNTRUSTED_CERTIFICATE,
        VDSP13.STATUS.INVALID
      )
    }

    if (!CACertificate) {
      throw new VDSError(
        i18n.t('VDSp13.UNTRUSTED_CERTIFICATE'),
        VDSP13.SUB_INDICATION.UNTRUSTED_CERTIFICATE,
        VDSP13.STATUS.INVALID
      )
    }

    this.CAcertificate = CACertificate

    const notBefore = moment(signerCertificate.notBefore.value)
    const notAfter = moment(signerCertificate.notAfter.value)
    const now = moment()

    if (now.isBefore(notBefore)) {
      throw new VDSError(
        i18n.t('VDSp13.SIGNER_CERTIFICATE_NOT_YET_VALID'),
        VDSP13.SUB_INDICATION.EXPIRED_CERTIFICATE,
        VDSP13.STATUS.INVALID
      )
    }

    if (now.isAfter(notAfter)) {
      throw new VDSError(
        i18n.t('VDSp13.SIGNER_CERTIFICATE_EXPIRED'),
        VDSP13.SUB_INDICATION.EXPIRED_CERTIFICATE,
        VDSP13.STATUS.INVALID
      )
    }

    let isSignatureValid
    try {
      isSignatureValid = await Crypto.verifySignatureWithCertificate(
        signerCertificate.certificate,
        this.signature.signatureData,
        new Uint8Array([...this.header.raw, ...this.message.raw])
      )
    } catch (error) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_SIGNATURE'),
        VDSP13.SUB_INDICATION.INVALID_SIGNATURE,
        VDSP13.STATUS.INVALID
      )
    }

    if (!isSignatureValid) {
      throw new VDSError(
        i18n.t('VDSp13.INVALID_SIGNATURE'),
        VDSP13.SUB_INDICATION.INVALID_SIGNATURE,
        VDSP13.STATUS.INVALID
      )
    }
  }
  //#endregion

  //#region Display functions
  toString() {
    // truncate hash to 7 digits for simple authentication in logs
    return `VDS [${this.UUID.substring(0, 7)}]`
  }

  log() {
    Cache.log(
      constants.INFO,
      this.toString(),
      {
        uuid: this.UUID,
        status: this.status,
        subIndication: this.subIndication,
        signerCertificate: this.signerCertificate?.cacheObject?.label || i18n.t('VDSp13.NOT_APPLICABLE'),
        CACertificate: this.CACertificate?.cacheObject?.label || i18n.t('VDSp13.NOT_APPLICABLE'),
      }
    )
  }

  prettyDisplay() {
    return <PrettyPage vds={this} />
  }

  rawDisplay() {
    return <RawPage vds={this} />
  }

  isPrettyDisplayable() {
    return this.hasKnownDocumentFeatures
  }
  //#endregion
}

export default VDSp13