/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import { Divider, Tabs, Tab } from '@mui/material'
import { WarningRounded } from '@mui/icons-material'
import { Typography } from '@mui/material'
import i18n from 'i18n-js'
import VDSNC from 'VDSNC'
import VDSp13 from 'VDSp13'
import * as constants from 'constants'
import Cache from 'common/Cache'
import Utils from 'common/Utils'
import { CircularProgress } from 'tegona-react'

function TabPanelComponent(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{
        ...styles.tabPanel,
        display: value === index ? 'block' : 'none'
      }}
      {...other}
    >
      {children}
    </div >
  )
}

const TabPanel = React.memo(TabPanelComponent)

function ErrorPage() {
  return (
    <div style={styles.errorPage}>
      <WarningRounded style={styles.errorIcon} />
      <Typography variant="h4" style={styles.errorText}>
        {i18n.t('VDSDataPage.ERROR_PAGE_TEXT')}
      </Typography>
    </div>
  )
}

function VDSDataPage(props) {
  const [VDS, setVDS] = React.useState(null)
  const [tab, setTab] = React.useState(0)

  React.useEffect(() => {
    handleNewData(props.data)
  }, [props.data])

  async function handleNewData(data) {
    setVDS(null) // trigger loading screen
    let newVDS

    const text = data.text
    const bytes = data.bytes instanceof Uint8Array
      ? Utils.Uint8ArrayToArrayBuffer(data.bytes)
      : data.bytes

    if (VDSp13.test(bytes)) {
      newVDS = new VDSp13(bytes)
    } else if (VDSNC.test(text)) {
      newVDS = new VDSNC(text)
    }

    if (!newVDS) {
      Cache.log(constants.ERROR, i18n.t('VDSDataPage.NOT_A_VDS'))
      setVDS('error')
      return
    }

    await newVDS.verify()

    newVDS.log()

    setVDS(newVDS)
    setTab(newVDS.isPrettyDisplayable ? 0 : 1)
  }

  return (
    !VDS
      ? <CircularProgress style={styles.circularProgress} />
      : VDS === 'error'
        ? <ErrorPage/>
        : <>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
            >
              <Tab label={i18n.t('VDSDataPage.TAB_1')} disabled={!VDS.isPrettyDisplayable} />
              <Tab label={i18n.t('VDSDataPage.TAB_2')} />
            </Tabs>
            <Divider/>
            <TabPanel value={tab} index={0}>
              { VDS.prettyDisplay() }
            </TabPanel>
            <TabPanel value={tab} index={1}>
              { VDS.rawDisplay() }
            </TabPanel>
          </>
  )
}

const drawerBleeding = 60
const styles = {
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  tabPanel: {
    top: drawerBleeding,
    overflow: 'auto'
  },
  errorPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    gap: '20px'
  },
  errorIcon: {
    fontSize: 200,
    color: 'orange'
  },
  errorText: {
    textAlign: 'center',
    maxWidth: '80%'
  }
}

export default VDSDataPage