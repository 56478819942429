/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

/**
 * Canonicalizes a given object according to RFC 8785.
 * @param {Object} object The object to canonicalize.
 * @returns {Object}      The canonicalized string representation of the object.
 */
export function canonicalizeObject(object) {
  function serialize(object) {
    if (object === null || typeof object !== 'object' || object.toJSON != null) {
      buffer += JSON.stringify(object)
    } else if (Array.isArray(object)) {
      buffer += '['
      let next = false
      object.forEach((element) => {
        if (next) {
          buffer += ','
        }
        next = true
        serialize(element)
      })
      buffer += ']'
    } else {
      buffer += '{'
      let next = false
      Object.keys(object).sort().forEach((property) => {
        if (next) {
          buffer += ','
        }
        next = true
        buffer += JSON.stringify(property)
        buffer += ':'
        serialize(object[property])
      })
      buffer += '}'
    }
  }

  let buffer = ''
  serialize(object)
  return buffer
}

/**
 * Strips URL encoding from a base64url encoded string.
 * @param {string} b64 The base64url encoded string.
 * @returns {string}   The base64 encoded string.
 */
export function stripUrlEncoding(b64) {
  return b64.replace(/_/g, '/').replace(/-/g, '+')
}

/**
 * Converts a base64 encoded string to an ArrayBuffer.
 * @param {string} b64    The base64 encoded string.
 * @returns {ArrayBuffer} The resulting ArrayBuffer.
 */
export function base64ToArrayBuffer(b64) {
  var byteString = window.atob(b64)
  var byteArray = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i)
  }
  return byteArray.buffer
}

/**
 * Converts an ArrayBuffer to a base64 encoded string.
 * @param {ArrayBuffer} buffer The ArrayBuffer to convert.
 * @returns {string}           The base64 encoded string.
 */
export function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/**
 * Converts an ArrayBuffer to a string.
 * @param {ArrayBuffer} buffer The ArrayBuffer to convert.
 * @returns {string}           The resulting string.
 */
export function arrayBufferToString(buffer) {
  let resultString = ""
  const view = new Uint8Array(buffer)

  for (const element of view) {
    resultString += String.fromCharCode(element)
  }

  return resultString
}

/**
 * Converts a string to an ArrayBuffer.
 * @param {string} str    The string to convert.
 * @returns {ArrayBuffer} The resulting ArrayBuffer.
 */
export function stringToArrayBuffer(str) {
  var buffer = new ArrayBuffer(str.length)
  var bufferView = new Uint8Array(buffer)
  for (var i = 0; i < str.length; i++) {
    bufferView[i] = str.charCodeAt(i)
  }
  return buffer
}

/**
 * Converts an ArrayBuffer to a hexadecimal string.
 * @param {ArrayBuffer} buffer The ArrayBuffer to convert.
 * @param {boolean} trim       Whether to trim leading zero bytes.
 * @param {number} spaceLength The number of bytes after which to insert a space.
 * @returns {string}           The resulting uppercase hexadecimal string.
 */
export function arrayBufferToHex(buffer, trim=false, spaceLength=0) {
  const bytes = Array.from(new Uint8Array(buffer))
  if (trim) {
    while (bytes[0] === 0) {
      bytes.shift()
    }
  }
  let hex = bytes.map(byte => byte.toString(16).padStart(2, '0')).join('')
  if (spaceLength > 0) {
    hex = hex.replace(new RegExp(`(.{${spaceLength}})`, 'g'), '$1 ').trim()
  }
  return hex
}

/**
 * Converts a hexadecimal string to an ArrayBuffer.
 * @param {string} hex    The hexadecimal string to convert.
 * @returns {ArrayBuffer} The resulting ArrayBuffer.
 */
export function hexToArrayBuffer(hex) {
  // Remove whitespaces
  hex = hex.replace(/\s/g, '')
  return new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16))).buffer
}

/**
 * Converts a Uint8Array to an ArrayBuffer.
 * @param {Uint8Array} array The Uint8Array to convert.
 * @returns {ArrayBuffer}    The resulting ArrayBuffer.
 */
export function Uint8ArrayToArrayBuffer(array) {
  return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
}

const Utils = {
  canonicalizeObject,
  stripUrlEncoding,
  base64ToArrayBuffer,
  arrayBufferToBase64,
  arrayBufferToString,
  stringToArrayBuffer,
  arrayBufferToHex,
  hexToArrayBuffer,
  Uint8ArrayToArrayBuffer
}

export default Utils