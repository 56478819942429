/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import React from 'react'
import Base from 'common/Base'
import i18n from 'i18n-js'
import Cache from 'common/Cache'
import Crypto from 'common/Crypto'
import Snackbar from 'common/Snackbar'
import { TextInput } from 'tegona-react'
import { Add, CheckBox, Remove, FileUpload } from "@mui/icons-material"
import * as constants from 'constants'
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  DialogActions,
  DialogContentText,
} from '@mui/material'

function Settings() {
  const [certificates, setCertificates] = React.useState([])
  const [checked, setChecked] = React.useState([])
  const [showCertificateDialog, setShowCertificateDialog] = React.useState(false)
  const [label, setLabel] = React.useState()
  const [selectedFile, setSelectedFile] = React.useState()
  const [errors, setErrors] = React.useState()

  function refreshCertificates() {
    Cache.getAll(constants.CACHE_VALUES.tables.certificates.name)
      .then(_certificates => setCertificates(_certificates))
      .catch((error) => {
        Snackbar.enqueue({
          message: i18n.t('Settings.CERTIFICATE_FETCHING_ERROR'),
          variant: 'error'
        })
        Cache.log(
          constants.ERROR,
          i18n.t('Settings.CERTIFICATE_FETCHING_ERROR'),
          error.toString()
        )
      })
    
    setChecked([])
  }

  React.useEffect(() => {
    refreshCertificates()
  }, [])

  function handleToggle(index) {
    const currentIndex = checked.indexOf(index)
    const newChecked = [...checked]

    currentIndex === -1 ? newChecked.push(index) : newChecked.splice(currentIndex, 1)

    setChecked(newChecked)
  }

  function handleDialogClose() {
    setShowCertificateDialog(false)
    setLabel()
    setErrors()
    setSelectedFile()
  }

  async function handleDialogSubmit() {
    if (!label) {
      setErrors({label: i18n.t('Settings.EMPTY_LABEL')})
      return
    }

    if (!selectedFile) {
      Snackbar.enqueue({
        message: i18n.t('Settings.NO_FILE_SELECTED'),
        variant: 'warning'
      })
      return
    }

    try {
      const certBuffer = await selectedFile.arrayBuffer()
      const cert = await Crypto.toCacheObject(certBuffer, label)

      if (certificates.find(c => c.thumbprint === cert.thumbprint)) {
        Snackbar.enqueue({
          message: i18n.t('Settings.CERTIFICATE_ALREADY_EXISTS'),
          variant: 'error'
        })
        return
      }

      Cache.put(constants.CACHE_VALUES.tables.certificates.name, cert)
        .catch((error) => {
          Snackbar.enqueue({
            message: i18n.t('Settings.CERTIFICATE_CACHING_ERROR'),
            variant: 'error'
          })
          Cache.log(
            constants.ERROR,
            i18n.t('Settings.CERTIFICATE_CACHING_ERROR'),
            error.toString()
          )
        })
      refreshCertificates()
    } catch (error) {
      Snackbar.enqueue({
        message: i18n.t('Settings.CERTIFICATE_PARSING_ERROR'),
        variant: 'error'
      })
      Cache.log(
        constants.ERROR,
        i18n.t('Settings.CERTIFICATE_PARSING_ERROR'),
        error.toString()
      )
      
      return
    }

    handleDialogClose()
  }

  async function handleFileInput(event) {
    event.target.files.length
    ? setSelectedFile(event.target.files[0])
    : setSelectedFile(null)
  }

  function handleRemove() {
    const keys = []
    checked.forEach(index => {
      keys.push(certificates[index].thumbprint)
    })

    for (const key of keys) {
      Cache.remove(constants.CACHE_VALUES.tables.certificates.name, key)
        .catch((error) => {
          Snackbar.enqueue({
            message: i18n.t('Settings.CERTIFICATE_REMOVING_ERROR'),
            variant: 'error'
          })
          Cache.log(
            constants.ERROR,
            i18n.t('Settings.CERTIFICATE_REMOVING_ERROR'),
            error.toString()
          )
        })
    }

    refreshCertificates()
  }

  return (
    <Base
    showHeader
    showDialog={showCertificateDialog}
      dialogTitle={i18n.t('Settings.DIALOG_TITLE')}
      dialogContent={
        <>
          <DialogContentText>
            {i18n.t('Settings.DIALOG_DESCRIPTION')}
          </DialogContentText>
          <TextInput
            required
            margin="dense"
            name="label"
            label="Label"
            type="text"
            value={label}
            onChange={(name, value) => { setLabel(value) }}
            errors={errors}
          />
          <Button
            component="label"
            startIcon={<FileUpload/>}
            variant="outlined"
            fullWidth
          >
            {selectedFile
              ? (selectedFile.name.length > 25
                ? `${selectedFile.name.substring(0, 25)}...`
                : selectedFile.name)
              : i18n.t('Settings.SELECT_FILE')}
            <input
              type="file"
              hidden
              onChange={handleFileInput}
            />
          </Button>
          <DialogActions>
            <Button onClick={handleDialogClose}>
              {i18n.t('Settings.DIALOG_CANCEL')}
            </Button>
            <Button onClick={handleDialogSubmit}>
              {i18n.t('Settings.DIALOG_SUBMIT')}
            </Button>
          </DialogActions>
        </>
      }
    >

      <List
        disablePadding
        style={styles.list}>
        {certificates.map((certificate, index) => (
          <div key={`itemContainer-${index}`}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => {handleToggle(index)}}>
                <ListItemText primary={certificate.label} secondary={certificate.subjectCommonName}/>
                {checked.indexOf(index) !== -1 &&
                  <ListItemIcon>
                    <CheckBox color='primary'/>
                  </ListItemIcon>}
              </ListItemButton>
            </ListItem>
            <Divider/>
          </div>
        ))}
      </List>

      <div style={styles.bottom}>
        <Divider/>
        <div style={styles.buttonContainer}>
          <Button
            onClick={() => setShowCertificateDialog(true)}
            style={styles.button}>
            <Add sx={styles.icon} />
          </Button>
          <Divider orientation='vertical'/>
          <Button
            onClick={handleRemove}
            disabled={checked.length === 0}
            style={styles.button}>
            <Remove sx={styles.icon}/>
          </Button>
        </div>
      </div>
    </Base>
  )
}

const styles = {
  list: {
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    maxHeight: '90%',
    height: '90%'
  },
  bottom: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    height: '10%',
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  button: {
    flex: '1',
    borderRadius: 0,
  },
  icon: {
    fontSize: 35,
  }
}

export default Settings