/*
 *  Copyright
 *
 *  Tegona S.A.
 *
 *  Vdschecker © 2024, Tegona S.A.
 *
 *  ALL RIGHTS RESERVED. THIS PROGRAM CONTAINS MATERIAL PROTECTED
 *  UNDER INTERNATIONAL AND SWITZERLAND COPYRIGHT LAWS AND TREATIES.
 *  ANY UNAUTHORIZED USE OF THE PROGRAM, INCLUDING REPRODUCTION,
 *  MODIFICATION, TRANSFER, TRANSMITTAL OR REPUBLICATION OF THIS
 *  MATERIAL IN ANY FORM OR BY ANY MEANS IS PROHIBITED WITHOUT
 *  SPECIFIC WRITTEN PERMISSION OF THE COPYRIGHT HOLDER.
 *
 *  copyright@tegona.com
 */

import { createTheme } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'

export const LOGIN_URL = '/'
export const PRIMARY = '#235585'
export const SECONDARY = '#FDC921'
export const BLACK = '#000000'
export const RED = '#dc2626'
export const GREEN = '#4CAF50'
export const ORANGE = '#ffa500'
export const ERROR = RED
export const MANDATORY = RED
export const GREY = '#0000008a'
export const LIGHT_GREY = '#b5b5b5'
export const WHITE = '#ffffff'

export const datePickerLocale = 'fr-CH'

/* Display format interfaces */
export const dateDisplayFormat = 'DD.MM.YYYY'
export const timeDisplayFormat = 'HH:mm'
export const dateTimeMinuteDisplayFormat = 'DD.MM.YYYY HH:mm'
export const dateTimeDisplayFormat = 'DD.MM.YYYY HH:mm:ss'

/* Back end format */
export const dateFormat = 'YYYY-MM-DD'
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const dateTimeMsFormat = 'YYYY-MM-DD HH:mm:ss.SSS'

/* MUI Input format */
export const inputDateFormat = 'dd.MM.yyyy'
export const inputDateTimeFormat = 'dd.MM.yyyy HH:mm'

export const muiTheme = createTheme({
  primary: PRIMARY,
  secondary: SECONDARY,
  black: BLACK,
  red: RED,
  green: GREEN,
  grey: GREY,
  lightGrey: LIGHT_GREY,
  white: WHITE,
  error: ERROR,
  mandatory: MANDATORY,
  palette: {
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    grey: {
      main: GREY
    },
    white: {
      main: WHITE
    },
    black: {
      main: BLACK
    },
    green: {
      main: GREEN
    },
    red: {
      main: RED
    },
    orange: {
      main: ORANGE
    },
    tonalOffset: 0.1,
    contrastThreshold: 1.5,
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '8vh'
    },
    h2: {
      fontSize: '4vh',
      fontWeight: 400
    },
    h3: {
      fontSize: '3.7vh',
      fontWeight: 600
    },
    h4: {
      fontSize: '2.7vh',
      fontWeight: 600
    },
    h5: {
      fontSize: '2.2vh',
      fontWeight: 600
    },
    subtitle2: {
      fontSize: '1.0625rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontSize: '0.725rem',
      fontWeight: 700
    }
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '3vh'
        }
      }
    },
  },
  locale: datePickerLocale
}, frFR)

